import * as ReactIs from "react-is";
export const hasChildren = (child)=>{
    if (!child) return false;
    if (ReactIs.isFragment(child)) {
        if (!child.props.children) return false;
        if (Array.isArray(child.props.children)) {
            return child.props.children.some((el)=>!!el);
        }
    }
    return true;
};
