import { useReservationStore } from "@cinch-labs/data-reservations";
import { sendCheckoutError } from "./helpers/send-checkout-error";
export const handleExtendReservation = async ({ orderId , token , orderServiceUrl  })=>{
    const { legacyExtendReservation  } = useReservationStore.getState();
    try {
        const res = await legacyExtendReservation(orderId, orderServiceUrl, token);
        return res;
    } catch (error) {
        sendCheckoutError("Error extending reservation (extendOrderExpiryDate)", error, orderId);
        return undefined;
    }
};
