import tracer from "dd-trace";
import { isTraceableEnvironment } from "./is-traceable-environment";
export const getDatadogTraceHeaders = ()=>{
    var ref, ref1;
    const headers = {};
    const traceId = (ref = tracer.scope().active()) === null || ref === void 0 ? void 0 : ref.context().toTraceId();
    const spanId = (ref1 = tracer.scope().active()) === null || ref1 === void 0 ? void 0 : ref1.context().toSpanId();
    if (traceId && spanId) {
        headers["x-datadog-trace-id"] = traceId;
        headers["x-datadog-parent-id"] = spanId;
        headers["x-datadog-sampling-priority"] = "1";
    }
    return headers;
};
/**
 * Force Datadog trace propagation headers into the fetch request
 *
 * You should only need this if trace propagation/distributed tracing isn't working for some reason
 */ export const fetchWithTracePropagation = (input, init)=>{
    if (!isTraceableEnvironment()) {
        return fetch(input, init);
    }
    const headers = input instanceof Request ? input.headers : (init === null || init === void 0 ? void 0 : init.headers) ?? new Headers();
    const ddHeaders = getDatadogTraceHeaders();
    if (headers instanceof Headers) {
        // headers is an instance of Headers
        Object.entries(ddHeaders).forEach(([key, value])=>{
            if (value) {
                headers.set(key, value);
            }
        });
    } else if (Array.isArray(headers)) {
        // headers is an array of tuples
        headers.push(...Object.entries(ddHeaders));
    } else {
        // headers is a record
        Object.entries(ddHeaders).forEach(([key, value])=>{
            headers[key] = value;
        });
    }
    return fetch(input, init);
};
