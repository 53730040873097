export const setCookie = ({ name , value , expiryInDays  })=>{
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + expiryInDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${expiryDate.toUTCString()}`;
    const isLocal = process.env["STAGE_NAME"] === "local";
    document.cookie = isLocal ? `${name}=${value};${expires};path=/` : `${name}=${value};${expires};path=/;domain=cinch.co.uk`;
};
export const getCookie = ({ name  })=>{
    var ref;
    return ((ref = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)) === null || ref === void 0 ? void 0 : ref.pop()) ?? undefined;
};
