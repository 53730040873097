import { useEffect, useRef } from "react";
export const useExpiry = (activeOrderExpiry, setShouldShowExpiringOrExpired, timeNow, warningTime, isExpiryingOrExpired)=>{
    const isExpiryingOrExpiredRef = useRef(isExpiryingOrExpired);
    useEffect(()=>{
        if (activeOrderExpiry) {
            const checkOrderStatus = activeOrderExpiry - warningTime;
            const timeToTriggerRefresh = checkOrderStatus - timeNow;
            const triggerRefresh = timeToTriggerRefresh > 0 ? timeToTriggerRefresh : 0;
            /* This will fire 5 minutes before the basket session runs out */ const timeoutId = setTimeout(()=>{
                setShouldShowExpiringOrExpired(isExpiryingOrExpiredRef.current);
            }, triggerRefresh);
            return ()=>clearTimeout(timeoutId);
        }
        return;
    }, [
        activeOrderExpiry,
        warningTime,
        timeNow
    ]);
};
