export const BASKET_BANNER_CLOSED = "cinch-checkout-basket-banner-closed";
export const getBasketBannerClosed = ()=>{
    if ("object" !== "undefined") {
        return JSON.parse(window.localStorage.getItem(BASKET_BANNER_CLOSED) ?? "false");
    }
    return false;
};
export const setBasketBannerClosed = (value)=>{
    if ("object" !== "undefined") {
        window.localStorage.setItem(BASKET_BANNER_CLOSED, value ? "true" : "false");
    }
};
export const clearBasketBannerClosed = ()=>{
    if ("object" !== "undefined") {
        window.localStorage.removeItem(BASKET_BANNER_CLOSED);
    }
};
