import { isBrowser, getAnalyticIDs } from "@cinch-labs/shared-util";
export const sendDataLayerEvent = (data)=>{
    if (isBrowser) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addServiceTrackingIdsToEvent = (data)=>{
    if ("object" === "undefined" || !Object.prototype.hasOwnProperty.call(data, "event")) {
        return data;
    }
    const { adobeID , gaID  } = getAnalyticIDs();
    const event = data["event"];
    if (adobeID) {
        event.adobeID = adobeID;
    }
    if (gaID) {
        event.gaID = gaID;
    }
    return {
        ...data,
        event
    };
};
export const sendDigitalDataEvent = (data)=>{
    if (window && data) {
        window.digitalData = window.digitalData || [];
        const trackableEvent = addServiceTrackingIdsToEvent(data);
        window.digitalData.push(trackableEvent);
    }
};
export const addToDigitalDataEvent = (data)=>{
    if ("object" !== "undefined" && window.digitalData.length > 0) {
        const updatedDigitDataLayer = window.digitalData.reduce((dataLayer, event)=>{
            const updatedDataLayerObject = {
                ...event,
                ...data
            };
            return [
                {
                    ...dataLayer,
                    ...updatedDataLayerObject
                }
            ];
        }, []);
        window.digitalData = updatedDigitDataLayer;
    }
};
export const sendHotjarEvent = (event)=>{
    if ("object" !== "undefined" && window.hj) {
        window.hj("event", event);
    }
};
export const sendHotjarTag = (tag)=>{
    if ("object" !== "undefined" && window.hj) {
        window.hj("tagRecording", [
            tag
        ]);
    }
};
export const sendHotjarTrigger = (triggerName)=>{
    if ("object" !== "undefined" && window.hj) {
        window.hj("trigger", triggerName);
    }
};
