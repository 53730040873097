import { environment as environmentFromFile } from "./environment";
export function readFromEnv(key) {
    const envValue = environmentFromFile[key];
    if (envValue === undefined || envValue === null) {
        throw new Error(`
        Missing environment variable value for key ${key}.
        Running locally: Do you have a .env.local file in your app folder that contains ${key}?
        Running in CI: Is ${key} exposed in the CI pipeline file?
        `);
    }
    return envValue;
}
export { Env } from "./environment.types";
