// TODO: lowercase this variable and import tags from ../../index
export const tagsData = [
    {
        label: "Nearly new",
        value: "nearly-new"
    },
    {
        label: "Cars on offer",
        value: "cars-on-offer"
    },
    {
        label: "Deposit contribution",
        value: "deposit-contribution"
    }
];
export const getCustomTagMessage = (label)=>{
    const customMessages = new Map([
        [
            "Cars on offer",
            "Only show discounted cars"
        ]
    ]);
    return customMessages.get(label) ?? label;
};
