import { isBrowser } from "@cinch-labs/shared-util";
export const getDataLayer = ()=>{
    if (isBrowser) {
        const dl = window.adobeData;
        if (dl) {
            return dl;
        }
    }
    return;
};
export const getTrackingTargetMeta = ()=>{
    if (isBrowser) {
        const ttMETA = window.ttMETA;
        return ttMETA ?? [];
    }
    return;
};
export const updateCompareInformation = (data)=>{
    const dl = getDataLayer();
    if (dl) {
        dl.page.compareInformation = data;
    }
};
// Still used by dealer favouriting
export const updateVehicleInformation = (data)=>{
    const dl = getDataLayer();
    if (dl) {
        dl.page.vehicleInformation = data;
    }
};
// Still used by dealer find vehicle
export const updateVehicleFilters = (data)=>{
    const dl = getDataLayer();
    if (dl) {
        dl.page.vehicleFilters = data;
    }
};
/**
 * @deprecated
 */ export const updateFindVehiclePanel = (data)=>{
    const dl = getDataLayer();
    if (dl) {
        dl.page.pageInfo.findVehiclePanel = data;
    }
};
