import { EventNames, TrackingEventTypes } from "@cinch-nx/shared-analytics";
export const handleButtonClick = (path, trackEvent, useBrowserNavigation, routerPush)=>{
    trackEvent({
        eventName: `unauth-save-vehicle-event`,
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: EventNames.click,
                category: `auth-modal-${path}-button`,
                label: path,
                action: `auth-modal-${path}`
            }
        }
    });
    useBrowserNavigation ? window.location.assign(`/${path}`) : routerPush(`/${path}`);
};
