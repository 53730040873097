export const driveTypesData = [
    {
        label: "Four-wheel drive",
        value: "four-wheel drive"
    },
    {
        label: "Front-wheel drive",
        value: "front-wheel drive"
    },
    {
        label: "Rear-wheel drive",
        value: "rear-wheel drive"
    }
];
