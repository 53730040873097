import { Env, readFromEnv } from "@cinch-nx/environments";
import { traceFunction, tagError, setTag } from "@core/datadog-server";
const STORYBLOK_BFF_URL = `${readFromEnv(Env.StoryblokBFFComponent)}`;
async function getTagsfn() {
    const params = new URLSearchParams({
        cv: Date.now().toString()
    });
    const url = `${STORYBLOK_BFF_URL}/get-tags?${params.toString()}`;
    setTag("request.url", url);
    try {
        const request = await fetch(url);
        setTag("request.url", url);
        setTag("request.params", Object.fromEntries(params.entries()));
        setTag("request.method", "GET");
        setTag("request.status", request.status);
        setTag("request.content-length", request.headers.get("content-length") ?? "0");
        const data = await request.json();
        return data.tags;
    } catch (error) {
        if (typeof error === "string" || error instanceof Error) {
            tagError(error);
        }
        throw new Error("get tags endpoint not working");
    }
}
export const getTags = traceFunction("storyblok.getTags", getTagsfn);
