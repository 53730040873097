import { isBrowser } from "./is-browser";
export var PaymentDecision;
(function(PaymentDecision) {
    PaymentDecision["PAY_IN_FULL"] = "PAY_IN_FULL";
    PaymentDecision["PAY_WITH_FINANCE"] = "PAY_WITH_FINANCE";
    PaymentDecision["NONE"] = "NONE";
})(PaymentDecision || (PaymentDecision = {}));
export const paymentDecisionInStorage = "paymentDecision";
export const getPaymentDecisionFromLocalStorage = ()=>{
    if ("object" === "undefined") {
        return null;
    }
    return window.localStorage.getItem(paymentDecisionInStorage);
};
export const cardDetailsPagePath = "/checkout/card-details/";
export const deliveryPagePath = "/checkout/delivery/";
export const personalDetailsPagePath = "/checkout/personal-details/";
export const findVehiclePath = "/find-vehicle/";
export const partExchangePagePath = "/checkout/part-exchange/";
export const financePagePath = getPaymentDecisionFromLocalStorage() === PaymentDecision.PAY_WITH_FINANCE ? "/checkout/finance/" : "/checkout/payment/";
export const cinchCarePagePath = "checkout/cinch-care/";
export const extrasPagePath = "/checkout/car-care/";
export const cinchCoverPagePath = "/checkout/cinch-cover/";
export var CheckoutStage;
(function(CheckoutStage) {
    CheckoutStage["PersonalDetails"] = "PersonalDetails";
    CheckoutStage["PartExchange"] = "PartExchange";
    CheckoutStage["Finance"] = "Finance";
    CheckoutStage["DeliverySlots"] = "DeliverySlots";
    CheckoutStage["Extras"] = "Extras";
    CheckoutStage["CinchCare"] = "CinchCare";
    CheckoutStage["CinchCover"] = "CinchCover";
    CheckoutStage["Payment"] = "Payment";
    CheckoutStage["SignUp"] = "SignUp";
})(CheckoutStage || (CheckoutStage = {}));
export const returnToCheckoutStage = (checkoutStep, activeOrder)=>{
    const vehicle = activeOrder && activeOrder.vehicle;
    const orderId = activeOrder && activeOrder.id;
    const stages = {
        [CheckoutStage.PartExchange]: partExchangePagePath,
        [CheckoutStage.Finance]: financePagePath,
        [CheckoutStage.DeliverySlots]: deliveryPagePath,
        [CheckoutStage.Extras]: extrasPagePath,
        [CheckoutStage.CinchCare]: cinchCarePagePath,
        [CheckoutStage.CinchCover]: cinchCoverPagePath,
        [CheckoutStage.Payment]: cardDetailsPagePath
    };
    if (stages[checkoutStep]) {
        return window.location.assign(`${stages[checkoutStep]}${orderId}`);
    }
    if (vehicle) {
        return window.location.assign(`${personalDetailsPagePath}${vehicle.id}`);
    }
    return;
};
const isOrderCheckoutStage = (maybeCheckoutStage)=>Object.prototype.hasOwnProperty.call(maybeCheckoutStage, "orderId") && Object.prototype.hasOwnProperty.call(maybeCheckoutStage, "stage");
export const getLastCheckoutStage = (activeOrder)=>{
    const storageLastCheckoutStage = isBrowser ? JSON.parse((sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem("checkout-last-visited-stage")) ?? "{}") : null;
    if (!activeOrder) {
        return;
    }
    if (storageLastCheckoutStage && isOrderCheckoutStage(storageLastCheckoutStage) && storageLastCheckoutStage.orderId === (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.id)) {
        return storageLastCheckoutStage;
    }
    return {
        orderId: activeOrder.id,
        stage: CheckoutStage.PersonalDetails
    };
};
export const checkoutTimes = (activeOrder)=>{
    const warningTime = 5 * 60 * 1000;
    const timeNow = Math.floor(Date.now());
    const timeRemainingInSeconds = activeOrder ? Math.floor(((activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) - timeNow) / 1000) : 0;
    const isExpiringSoon = (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) ? warningTime + timeNow > (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) : null;
    return {
        timeRemainingInSeconds,
        isExpiringSoon
    };
};
