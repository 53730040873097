const data = {
    ABARTH: {
        displayName: "Abarth",
        models: {
            "500": "500",
            "595": "595",
            "695": "695",
            "124 SPIDER": "124 Spider",
            "GRANDE PUNTO": "Grande Punto",
            "PUNTO EVO": "Punto Evo"
        }
    },
    AC: {
        displayName: "AC",
        models: {
            COBRA: "Cobra"
        }
    },
    "ALFA ROMEO": {
        displayName: "Alfa Romeo",
        models: {
            "145": "145",
            "146": "146",
            "147": "147",
            "155": "155",
            "156": "156",
            "159": "159",
            "164": "164",
            "166": "166",
            "4C": "4C",
            "8C": "8C",
            BRERA: "Brera",
            GIULIA: "Giulia",
            GIULIETTA: "Giulietta",
            GT: "GT",
            GTV: "GTV",
            MITO: "Mito",
            SPIDER: "Spider",
            STELVIO: "Stelvio"
        }
    },
    ARIEL: {
        displayName: "Ariel",
        models: {
            ATOM: "Atom",
            NOMAD: "Nomad"
        }
    },
    "ASTON MARTIN": {
        displayName: "Aston Martin",
        models: {
            CYGNET: "Cygnet",
            DB11: "DB11",
            DB7: "DB7",
            DB9: "DB9",
            DBS: "DBs",
            RAPIDE: "Rapide",
            VANQUISH: "Vanquish",
            VANTAGE: "Vantage",
            VIRAGE: "Virage"
        }
    },
    AUDI: {
        displayName: "Audi",
        models: {
            "80": "80",
            A1: "A1",
            A2: "A2",
            A3: "A3",
            A4: "A4",
            "A4 ALLROAD": "A4 Allroad",
            A5: "A5",
            A6: "A6",
            "A6 ALLROAD": "A6 Allroad",
            A7: "A7",
            A8: "A8",
            ALLROAD: "Allroad",
            CABRIOLET: "Cabriolet",
            CONVERTIBLE: "Convertible",
            COUPE: "Coupe",
            "E-TRON": "e-tron",
            Q2: "Q2",
            Q3: "Q3",
            Q5: "Q5",
            Q7: "Q7",
            Q8: "Q8",
            R8: "R8",
            "RS Q3": "RS Q3",
            RS3: "RS3",
            RS4: "RS4",
            RS5: "RS5",
            RS6: "RS6",
            RS7: "RS7",
            S1: "S1",
            S3: "S3",
            S4: "S4",
            S5: "S5",
            S6: "S6",
            S7: "S7",
            S8: "S8",
            SQ5: "SQ5",
            SQ7: "SQ7",
            TT: "TT",
            "TT RS": "TT RS",
            TTS: "TTS"
        }
    },
    AUSTIN: {
        displayName: "Austin",
        models: {
            HEALEY: "Healey"
        }
    },
    BENTLEY: {
        displayName: "Bentley",
        models: {
            ARNAGE: "Arnage",
            AZURE: "Azure",
            BENTAYGA: "Bentayga",
            BROOKLANDS: "Brooklands",
            CONTINENTAL: "Continental",
            "FLYING SPUR": "Flying Spur",
            MULSANNE: "Mulsanne",
            "TURBO R": "Turbo R"
        }
    },
    BMW: {
        displayName: "BMW",
        models: {
            "1 SERIES": "1 Series",
            "2 SERIES": "2 Series",
            "3 SERIES": "3 Series",
            "4 SERIES": "4 Series",
            "5 SERIES": "5 Series",
            "6 SERIES": "6 Series",
            "7 SERIES": "7 Series",
            "8 SERIES": "8 Series",
            ALPINA: "Alpina",
            I3: "I3",
            I8: "I8",
            M2: "M2",
            M3: "M3",
            M4: "M4",
            M5: "M5",
            M6: "M6",
            X1: "X1",
            X2: "X2",
            X3: "X3",
            X4: "X4",
            X5: "X5",
            X6: "X6",
            Z1: "Z1",
            Z3: "Z3",
            Z4: "Z4",
            Z8: "Z8"
        }
    },
    BUGATTI: {
        displayName: "Bugatti",
        models: {
            VEYRON: "Veyron"
        }
    },
    CATERHAM: {
        displayName: "Caterham",
        models: {
            SEVEN: "Seven"
        }
    },
    CHEVROLET: {
        displayName: "Chevrolet",
        models: {
            AVEO: "Aveo",
            BLAZER: "Blazer",
            C6: "C6",
            CAMARO: "Camaro",
            CAPTIVA: "Captiva",
            CORVETTE: "Corvette",
            CRUZE: "Cruze",
            EPICA: "Epica",
            KALOS: "Kalos",
            LACETTI: "Lacetti",
            MATIZ: "Matiz",
            ORLANDO: "Orlando",
            SPARK: "Spark",
            STINGRAY: "Stingray",
            TACUMA: "Tacuma",
            TRAX: "Trax",
            VOLT: "Volt"
        }
    },
    CHRYSLER: {
        displayName: "Chrysler",
        models: {
            "300C": "300C",
            CROSSFIRE: "Crossfire",
            DELTA: "Delta",
            "GRAND VOYAGER": "Grand Voyager",
            NEON: "Neon",
            "PT CRUISER": "PT Cruiser",
            SEBRING: "Sebring",
            VOYAGER: "Voyager",
            YPSILON: "Ypsilon"
        }
    },
    CITROEN: {
        displayName: "Citroen",
        models: {
            "BERLINGO FIRST": "Berlingo First",
            "BERLINGO MULTISPACE": "Berlingo Multispace",
            "C ZERO": "C ZERO",
            C1: "C1",
            C2: "C2",
            C3: "C3",
            "C3 AIRCROSS": "C3 Aircross",
            "C3 PICASSO": "C3 Picasso",
            "C3 PLURIEL": "C3 Pluriel",
            C4: "C4",
            "C4 CACTUS": "C4 Cactus",
            "C4 PICASSO": "C4 Picasso",
            C5: "C5",
            C6: "C6",
            C8: "C8",
            "C-CROSSER": "C-Crosser",
            "DISPATCH COMBI": "Dispatch Combi",
            DS3: "DS3",
            DS4: "DS4",
            DS5: "DS5",
            "GRAND C4 PICASSO": "Grand C4 Picasso",
            "NEMO MULTISPACE": "Nemo Multispace",
            SAXO: "Saxo",
            SYNERGIE: "Synergie",
            XANTIA: "Xantia",
            XSARA: "Xsara",
            "XSARA PICASSO": "Xsara Picasso"
        }
    },
    DACIA: {
        displayName: "Dacia",
        models: {
            DUSTER: "Duster",
            LOGAN: "Logan",
            "LOGAN STEPWAY": "Logan Stepway",
            SANDERO: "Sandero",
            "SANDERO STEPWAY": "Sandero Stepway"
        }
    },
    DAEWOO: {
        displayName: "Daewoo",
        models: {
            KALOS: "Kalos",
            KORANDO: "Korando",
            LACETTI: "Lacetti",
            LANOS: "Lanos",
            LEGANZA: "Leganza",
            MATIZ: "Matiz",
            MUSSO: "Musso",
            NUBIRA: "Nubira",
            TACUMA: "Tacuma"
        }
    },
    DAIHATSU: {
        displayName: "Daihatsu",
        models: {
            CHARADE: "Charade",
            COPEN: "Copen",
            CUORE: "Cuore",
            FOURTRAK: "Fourtrak",
            "GRAND MOVE": "Grand Move",
            MATERIA: "Materia",
            MOVE: "Move",
            SIRION: "Sirion",
            SPORTRAK: "Sportrak",
            TERIOS: "Terios",
            YRV: "Yrv"
        }
    },
    DODGE: {
        displayName: "Dodge",
        models: {
            AVENGER: "Avenger",
            CALIBER: "Caliber",
            CHALLENGER: "Challenger",
            JOURNEY: "Journey",
            NITRO: "Nitro"
        }
    },
    DS: {
        displayName: "DS",
        models: {
            "DS 3": "DS 3",
            "DS 4": "DS 4",
            "DS 5": "DS 5",
            "DS 7": "DS 7"
        }
    },
    FERRARI: {
        displayName: "Ferrari",
        models: {
            "308": "308",
            "328": "328",
            "348": "348",
            "365": "365",
            "430": "430",
            "456": "456",
            "458": "458",
            "488": "488",
            "512": "512",
            "550": "550",
            "599": "599",
            "612": "612",
            "360M": "360M",
            "456M": "456M",
            "488 GTB": "488 GTB",
            "575M": "575M",
            "812 SUPERFAST": "812 Superfast",
            CALIFORNIA: "California",
            DINO: "Dino",
            ENZO: "Enzo",
            "F12 BERLINETTA": "F12 Berlinetta",
            F355: "F355",
            F430: "F430",
            FF: "FF",
            "GTC4 LUSSO": "GTC4 Lusso",
            LAFERRARI: "Laferrari",
            PORTOFINO: "Portofino",
            TESTAROSSA: "Testarossa"
        }
    },
    FIAT: {
        displayName: "Fiat",
        models: {
            "500": "500",
            "124 SPIDER": "124 Spider",
            "500L": "500L",
            "500L MPW": "500L MPW",
            "500X": "500X",
            BARCHETTA: "Barchetta",
            BRAVA: "Brava",
            BRAVO: "Bravo",
            CINQUECENTO: "Cinquecento",
            COUPE: "Coupe",
            CROMA: "Croma",
            DOBLO: "Doblo",
            FIORINO: "Fiorino",
            "GRANDE PUNTO": "Grande Punto",
            IDEA: "Idea",
            MAREA: "Marea",
            MULTIPLA: "Multipla",
            PANDA: "Panda",
            PUNTO: "Punto",
            "PUNTO EVO": "Punto Evo",
            QUBO: "Qubo",
            SCUDO: "Scudo",
            SEDICI: "Sedici",
            SEICENTO: "Seicento",
            STILO: "Stilo",
            TIPO: "Tipo",
            ULYSSE: "Ulysse"
        }
    },
    FORD: {
        displayName: "Ford",
        models: {
            "B-MAX": "B-MAX",
            CAPRI: "Capri",
            "C-MAX": "C-MAX",
            COUGAR: "Cougar",
            ECOSPORT: "Ecosport",
            EDGE: "Edge",
            ESCORT: "Escort",
            EXPLORER: "Explorer",
            F150: "F150",
            FIESTA: "Fiesta",
            FOCUS: "Focus",
            FUSION: "Fusion",
            GALAXY: "Galaxy",
            "GRAND C-MAX": "Grand C-MAX",
            "GRAND TOURNEO CONNECT": "Grand Tourneo Connect",
            KA: "KA",
            "KA+": "KA+",
            KUGA: "Kuga",
            MAVERICK: "Maverick",
            MONDEO: "Mondeo",
            MUSTANG: "Mustang",
            PROBE: "Probe",
            PUMA: "Puma",
            RANGER: "Ranger",
            SCORPIO: "Scorpio",
            SIERRA: "Sierra",
            "S-MAX": "S-MAX",
            "S-MAX VIGNALE": "S-MAX Vignale",
            "FIESTA VIGNALE": "Fiesta Vignale",
            "FOCUS VIGNALE": "Focus Vignale",
            "MONDEO VIGNALE": "Mondeo Vignale",
            "KUGA VIGNALE": "Kuga Vignale",
            STREETKA: "Streetka",
            "TOURNEO CONNECT": "Tourneo Connect",
            "TRANSIT COURIER": "Transit Courier"
        }
    },
    "GREAT WALL": {
        displayName: "Great Wall",
        models: {
            STEED: "Steed"
        }
    },
    HONDA: {
        displayName: "Honda",
        models: {
            ACCORD: "Accord",
            AERODECK: "Aerodeck",
            CIVIC: "Civic",
            "CR-V": "CR-V",
            "CR-X": "CR-X",
            "CR-Z": "CR-Z",
            "FR-V": "FR-V",
            "HR-V": "HR-V",
            INSIGHT: "Insight",
            INTEGRA: "Integra",
            JAZZ: "Jazz",
            LEGEND: "Legend",
            LOGO: "Logo",
            NSX: "Nsx",
            PRELUDE: "Prelude",
            S2000: "S2000",
            SHUTTLE: "Shuttle",
            STEPWAGON: "Stepwagon",
            STREAM: "Stream"
        }
    },
    HUMMER: {
        displayName: "Hummer",
        models: {
            H2: "H2",
            H3: "H3"
        }
    },
    HYUNDAI: {
        displayName: "Hyundai",
        models: {
            ACCENT: "Accent",
            AMICA: "Amica",
            ATOZ: "Atoz",
            COUPE: "Coupe",
            ELANTRA: "Elantra",
            GENESIS: "Genesis",
            GETZ: "Getz",
            GRANDEUR: "Grandeur",
            I10: "I10",
            I20: "I20",
            I30: "I30",
            I40: "I40",
            I800: "I800",
            IONIQ: "IONIQ",
            IX20: "IX20",
            IX35: "IX35",
            KONA: "Kona",
            LANTRA: "Lantra",
            MATRIX: "Matrix",
            "SANTA FE": "Santa Fe",
            SONATA: "Sonata",
            TERRACAN: "Terracan",
            TRAJET: "Trajet",
            TUCSON: "Tucson",
            VELOSTER: "Veloster",
            XG30: "XG30"
        }
    },
    INFINITI: {
        displayName: "Infiniti",
        models: {
            EX: "EX",
            FX: "FX",
            G37: "G37",
            M: "M",
            Q30: "Q30",
            Q50: "Q50",
            Q60: "Q60",
            Q70: "Q70",
            QX30: "QX30",
            QX50: "QX50",
            QX70: "QX70"
        }
    },
    ISUZU: {
        displayName: "Isuzu",
        models: {
            TROOPER: "Trooper"
        }
    },
    JAGUAR: {
        displayName: "Jaguar",
        models: {
            "E-PACE": "E-PACE",
            "E-TYPE": "E-TYPE",
            "F-PACE": "F-PACE",
            "F-TYPE": "F-TYPE",
            "I-PACE": "I-PACE",
            SOVEREIGN: "Sovereign",
            "S-TYPE": "S-TYPE",
            "V8 XJ SERIES": "V8 XJ Series",
            XE: "XE",
            XF: "XF",
            XJ: "XJ",
            "XJ SERIES": "XJ Series",
            XJ12: "XJ12",
            XJ6: "XJ6",
            XJR: "XJR",
            XJS: "XJS",
            XK: "XK",
            XK8: "XK8",
            XKR: "XKR",
            "X-TYPE": "X-TYPE"
        }
    },
    JEEP: {
        displayName: "Jeep",
        models: {
            CHEROKEE: "Cherokee",
            COMMANDER: "Commander",
            COMPASS: "Compass",
            "GRAND CHEROKEE": "Grand Cherokee",
            PATRIOT: "Patriot",
            RENEGADE: "Renegade",
            WRANGLER: "Wrangler"
        }
    },
    KIA: {
        displayName: "KIA",
        models: {
            CARENS: "Carens",
            CEED: "Ceed",
            CERATO: "Cerato",
            CLARUS: "Clarus",
            MAGENTIS: "Magentis",
            MENTOR: "Mentor",
            "MENTOR II": "Mentor II",
            NIRO: "Niro",
            OPTIMA: "Optima",
            PICANTO: "Picanto",
            "PRO CEED": "ProCeed",
            RIO: "Rio",
            SEDONA: "Sedona",
            SHUMA: "Shuma",
            "SHUMA II": "Shuma II",
            SORENTO: "Sorento",
            SOUL: "Soul",
            SPORTAGE: "Sportage",
            STINGER: "Stinger",
            STONIC: "Stonic",
            VENGA: "Venga"
        }
    },
    LAMBORGHINI: {
        displayName: "Lamborghini",
        models: {
            AVENTADOR: "Aventador",
            COUNTACH: "Countach",
            DIABLO: "Diablo",
            GALLARDO: "Gallardo",
            HURACAN: "Hurac\xe1n",
            MURCIELAGO: "Murcielago",
            URUS: "Urus"
        }
    },
    LANCIA: {
        displayName: "Lancia",
        models: {
            DELTA: "Delta"
        }
    },
    "LAND ROVER": {
        displayName: "Land Rover",
        models: {
            DEFENDER: "Defender",
            DISCOVERY: "Discovery",
            "DISCOVERY SPORT": "Discovery Sport",
            FREELANDER: "Freelander",
            "RANGE ROVER": "Range Rover",
            "RANGE ROVER EVOQUE": "Range Rover Evoque",
            "RANGE ROVER SPORT": "Range Rover Sport",
            "RANGE ROVER VELAR": "Range Rover Velar"
        }
    },
    LEXUS: {
        displayName: "Lexus",
        models: {
            CT: "CT",
            GS: "GS",
            IS: "IS",
            LC: "LC",
            LS: "LS",
            NX: "NX",
            RC: "RC",
            RX: "RX",
            SC: "SC"
        }
    },
    LOTUS: {
        displayName: "Lotus",
        models: {
            "2-ELEVEN": "2-Eleven",
            ELISE: "Elise",
            ESPRIT: "Esprit",
            EUROPA: "Europa",
            EVORA: "Evora",
            EXIGE: "Exige"
        }
    },
    MASERATI: {
        displayName: "Maserati",
        models: {
            "3200": "3200",
            "4200": "4200",
            GHIBLI: "Ghibli",
            GRANCABRIO: "Grancabrio",
            GRANSPORT: "Gransport",
            GRANTURISMO: "Granturismo",
            LEVANTE: "Levante",
            QUATTROPORTE: "Quattroporte",
            SPYDER: "Spyder"
        }
    },
    MAYBACH: {
        displayName: "Maybach",
        models: {
            "57": "57",
            "62": "62"
        }
    },
    MAZDA: {
        displayName: "Mazda",
        models: {
            "2": "2",
            "3": "3",
            "5": "5",
            "6": "6",
            "121": "121",
            "323": "323",
            "626": "626",
            "CX-3": "CX-3",
            "CX-5": "CX-5",
            "CX-7": "CX-7",
            DEMIO: "Demio",
            MPV: "MPV",
            "MX-3": "MX-3",
            "MX-5": "MX-5",
            PREMACY: "Premacy",
            "RX-7": "RX-7",
            "RX-8": "RX-8",
            TRIBUTE: "Tribute",
            "XEDOS 6": "Xedos 6",
            "XEDOS 9": "Xedos 9"
        }
    },
    MCLAREN: {
        displayName: "McLaren",
        models: {
            "540": "540",
            "570": "570",
            "650S": "650S",
            "675LT": "675LT",
            "720S": "720S",
            "MP4-12C": "MP4-12C",
            P1: "P1",
            SENNA: "Senna"
        }
    },
    "MERCEDES-BENZ": {
        displayName: "Mercedes-Benz",
        models: {
            "190": "190",
            "200": "200",
            "300": "300",
            "A CLASS": "A-Class",
            "A-CLASS": "A-Class",
            "AMG GT": "AMG GT",
            "B CLASS": "B-Class",
            "B-CLASS": "B-Class",
            "C CLASS": "C-Class",
            "C-CLASS": "C-Class",
            "CL CLASS": "CL-Class",
            "CL-CLASS": "CL-Class",
            CLA: "CLA",
            CLC: "CLC",
            CLK: "CLK",
            CLS: "CLS",
            "E CLASS": "E-Class",
            "E-CLASS": "E-Class",
            "G CLASS": "G-Class",
            "G-CLASS": "G-Class",
            "GL CLASS": "GL-Class",
            "GL-CLASS": "GL-Class",
            GLA: "GLA",
            GLC: "GLC",
            GLE: "GLE",
            GLS: "GLS",
            "M CLASS": "M-Class",
            "M-CLASS": "M-Class",
            "MCLAREN SLR": "McLaren SLR",
            "R CLASS": "R-Class",
            "R-CLASS": "R-Class",
            "S CLASS": "S-Class",
            "S-CLASS": "S-Class",
            "SEL SERIES": "SEL Series",
            "SL CLASS": "SL-Class",
            "SL-CLASS": "SL-Class",
            SLC: "SLC",
            SLK: "SLK",
            SLS: "SLS",
            "V CLASS": "V-Class",
            "V-CLASS": "V-Class",
            VANEO: "Vaneo",
            VIANO: "Viano",
            "X CLASS": "X-Class",
            "X-CLASS": "X-Class"
        }
    },
    MG: {
        displayName: "MG",
        models: {
            MG3: "MG3",
            MG6: "MG6",
            MGF: "MGF",
            MGTF: "MGTF",
            ZR: "ZR",
            ZS: "ZS",
            ZT: "ZT",
            "ZT-T": "ZT-T"
        }
    },
    MINI: {
        displayName: "Mini",
        models: {
            CLUBMAN: "Clubman",
            CONVERTIBLE: "Convertible",
            COUNTRYMAN: "Countryman",
            COUPE: "Coupe",
            HATCHBACK: "Hatchback",
            PACEMAN: "Paceman",
            ROADSTER: "Roadster"
        }
    },
    MITSUBISHI: {
        displayName: "Mitsubishi",
        models: {
            "3000GT": "3000GT",
            ASX: "ASX",
            CARISMA: "Carisma",
            CHALLENGER: "Challenger",
            COLT: "Colt",
            "ECLIPSE CROSS": "Eclipse Cross",
            GALANT: "Galant",
            GRANDIS: "Grandis",
            GTO: "GTO",
            "I-CAR": "iCar",
            "I-MIEV": "i-MiEV",
            L200: "L200",
            LANCER: "Lancer",
            MIRAGE: "Mirage",
            OUTLANDER: "Outlander",
            PAJERO: "Pajero",
            PININ: "Pinin",
            "RALLIART EVOLUTION VI": "Ralliart Evolution VI",
            "RALLIART EVOLUTION VII": "Ralliart Evolution VII",
            "RALLIART FTO": "Ralliart FTO",
            "RALLIART GALANT VR4": "Ralliart Galant VR4",
            SHOGUN: "Shogun",
            "SHOGUN PININ": "Shogun Pinin",
            "SHOGUN SPORT": "Shogun Sport",
            "SPACE RUNNER": "Space Runner",
            "SPACE STAR": "Space Star",
            "SPACE WAGON": "Space Wagon"
        }
    },
    MORGAN: {
        displayName: "Morgan",
        models: {
            "4/4": "4/4",
            "PLUS 4": "Plus 4"
        }
    },
    NISSAN: {
        displayName: "Nissan",
        models: {
            "200SX": "200SX",
            "350Z": "350Z",
            "370Z": "370Z",
            ALMERA: "Almera",
            "ALMERA TINO": "Almera Tino",
            CUBE: "Cube",
            "E-NV200": "E-NV200",
            "GT-R": "GT-R",
            JUKE: "Juke",
            LEAF: "Leaf",
            "MAXIMA QX": "Maxima QX",
            MICRA: "Micra",
            MURANO: "Murano",
            NAVARA: "Navara",
            NOTE: "Note",
            NV200: "NV200",
            PATHFINDER: "Pathfinder",
            PATROL: "Patrol",
            PIXO: "Pixo",
            PRIMERA: "Primera",
            PULSAR: "Pulsar",
            QASHQAI: "Qashqai",
            "QASHQAI+2": "Qashqai+2",
            QX: "QX",
            SERENA: "Serena",
            "SKYLINE GT-R": "Skyline GT-R",
            "TERRANO II": "Terrano II",
            TIIDA: "Tiida",
            "X-TRAIL": "X-Trail"
        }
    },
    NOBLE: {
        displayName: "Noble",
        models: {
            M12: "M12"
        }
    },
    OPEL: {
        displayName: "Opel",
        models: {
            ASTRA: "Astra",
            INSIGNIA: "Insignia"
        }
    },
    PAGANI: {
        displayName: "Pagani",
        models: {
            HUAYRA: "Huayra"
        }
    },
    PERODUA: {
        displayName: "Perodua",
        models: {
            KELISA: "Kelisa",
            KENARI: "Kenari",
            MYVI: "Myvi"
        }
    },
    PEUGEOT: {
        displayName: "Peugeot",
        models: {
            "106": "106",
            "107": "107",
            "108": "108",
            "205": "205",
            "206": "206",
            "207": "207",
            "208": "208",
            "306": "306",
            "307": "307",
            "308": "308",
            "405": "405",
            "406": "406",
            "407": "407",
            "508": "508",
            "607": "607",
            "806": "806",
            "807": "807",
            "1007": "1007",
            "2008": "2008",
            "3008": "3008",
            "4007": "4007",
            "5008": "5008",
            "BIPPER TEPEE": "Bipper Tepee",
            "EXPERT TEPEE": "Expert Tepee",
            ION: "iOn",
            "PARTNER COMBI": "Partner Combi",
            "PARTNER ORIGIN COMBI": "Partner Origin Combi",
            "PARTNER TEPEE": "Partner Tepee",
            RCZ: "RCZ",
            TRAVELLER: "Traveller"
        }
    },
    PORSCHE: {
        displayName: "Porsche",
        models: {
            "356": "356",
            "718": "718",
            "911": "911",
            "912": "912",
            "918": "918",
            "924": "924",
            "928": "928",
            "944": "944",
            "968": "968",
            BOXSTER: "Boxster",
            "CARRERA GT": "Carrera GT",
            CAYENNE: "Cayenne",
            CAYMAN: "Cayman",
            MACAN: "Macan",
            PANAMERA: "Panamera"
        }
    },
    PROTON: {
        displayName: "Proton",
        models: {
            EXORA: "Exora",
            "GEN-2": "GEN-2",
            IMPIAN: "Impian",
            PERSONA: "Persona",
            PREVE: "Preve",
            SATRIA: "Satria",
            "SATRIA NEO": "Satria Neo",
            SAVVY: "Savvy",
            WIRA: "Wira"
        }
    },
    RENAULT: {
        displayName: "Renault",
        models: {
            AVANTIME: "Avantime",
            CAPTUR: "Captur",
            CLIO: "Clio",
            ESPACE: "Espace",
            "GRAND ESPACE": "Grand Espace",
            "GRAND MODUS": "Grand Modus",
            "GRAND SCENIC": "Grand Scenic",
            KADJAR: "Kadjar",
            KANGOO: "Kangoo",
            KOLEOS: "Koleos",
            LAGUNA: "Laguna",
            MEGANE: "Megane",
            "MEGANE SCENIC": "Megane Scenic",
            MODUS: "Modus",
            SCENIC: "Scenic",
            "SCENIC XMOD": "Scenic XMOD",
            TRAFFIC: "Traffic",
            TWINGO: "Twingo",
            TWIZY: "Twizy",
            "VEL SATIS": "Vel Satis",
            WIND: "Wind",
            ZOE: "Zoe"
        }
    },
    "ROLLS-ROYCE": {
        displayName: "Rolls-Royce",
        models: {
            CORNICHE: "Corniche",
            DAWN: "Dawn",
            GHOST: "Ghost",
            "PARK WARD": "Park Ward",
            PHANTOM: "Phantom",
            "SILVER DAWN": "Silver Dawn",
            "SILVER SERAPH": "Silver Seraph",
            "SILVER SHADOW": "Silver Shadow",
            "SILVER SPIRIT": "Silver Spirit",
            "SILVER SPUR": "Silver Spur",
            WRAITH: "Wraith"
        }
    },
    ROVER: {
        displayName: "Rover",
        models: {
            "25": "25",
            "45": "45",
            "75": "75",
            "100": "100",
            "200": "200",
            "400": "400",
            "600": "600",
            CITYROVER: "Cityrover",
            MINI: "Mini",
            STREETWISE: "Streetwise",
            TOURER: "Tourer"
        }
    },
    SAAB: {
        displayName: "Saab",
        models: {
            "900": "900",
            "9000": "9000",
            "9-3": "9-3",
            "9-3X": "9-3X",
            "9-5": "9-5"
        }
    },
    SEAT: {
        displayName: "SEAT",
        models: {
            ALHAMBRA: "Alhambra",
            ALTEA: "Altea",
            ARONA: "Arona",
            AROSA: "Arosa",
            ATECA: "Ateca",
            CORDOBA: "Cordoba",
            EXEO: "Exeo",
            IBIZA: "Ibiza",
            LEON: "Leon",
            "LEON X-PERIENCE": "Leon X-PERIENCE",
            MII: "Mii",
            TOLEDO: "Toledo"
        }
    },
    SKODA: {
        displayName: "Skoda",
        models: {
            CITIGO: "Citigo",
            FABIA: "Fabia",
            FELICIA: "Felicia",
            KAROQ: "Karoq",
            KODIAQ: "Kodiaq",
            OCTAVIA: "Octavia",
            RAPID: "Rapid",
            ROOMSTER: "Roomster",
            SUPERB: "Superb",
            YETI: "Yeti"
        }
    },
    SMART: {
        displayName: "Smart",
        models: {
            CITY: "City",
            FORFOUR: "Forfour",
            FORTWO: "Fortwo",
            ROADSTER: "Roadster"
        }
    },
    SPYKER: {
        displayName: "Spyker",
        models: {
            C8: "C8"
        }
    },
    SSANGYONG: {
        displayName: "SsangYong",
        models: {
            KORANDO: "Korando",
            KYRON: "Kyron",
            MUSSO: "Musso",
            REXTON: "Rexton",
            "REXTON W": "Rexton W",
            RODIUS: "Rodius",
            SPORTS: "Sports",
            TIVOLI: "Tivoli",
            "TIVOLI XVL": "Tivoli XVL",
            TURISMO: "Turismo"
        }
    },
    SUBARU: {
        displayName: "Subaru",
        models: {
            BRZ: "BRZ",
            FORESTER: "Forester",
            IMPREZA: "Impreza",
            JUSTY: "Justy",
            LEGACY: "Legacy",
            LEVORG: "Levorg",
            OUTBACK: "Outback",
            TRIBECA: "Tribeca",
            "WRX STI": "WRX STI",
            XV: "XV"
        }
    },
    SUZUKI: {
        displayName: "Suzuki",
        models: {
            ALTO: "Alto",
            BALENO: "Baleno",
            CAPPUCCINO: "Cappuccino",
            CELERIO: "Celerio",
            "GRAND VITARA": "Grand Vitara",
            IGNIS: "Ignis",
            JIMNY: "Jimny",
            KIZASHI: "Kizashi",
            LIANA: "Liana",
            SPLASH: "Splash",
            SWIFT: "Swift",
            SX4: "SX4",
            "SX4 S CROSS": "SX4 S-Cross",
            VITARA: "Vitara",
            "WAGON R": "Wagon R",
            "X-90": "X-90"
        }
    },
    TESLA: {
        displayName: "Tesla",
        models: {
            "MODEL S": "Model S",
            "MODEL X": "Model X"
        }
    },
    TOYOTA: {
        displayName: "Toyota",
        models: {
            "4RUNNER": "4Runner",
            AURIS: "Auris",
            AVENSIS: "Avensis",
            AYGO: "Aygo",
            CAMRY: "Camry",
            "CARINA E": "Carina E",
            CELICA: "Celica",
            "C-HR": "C-HR",
            COROLLA: "Corolla",
            GT86: "GT86",
            HILUX: "Hilux",
            IQ: "IQ",
            "LAND CRUISER": "Land Cruiser",
            MR2: "MR2",
            PASEO: "Paseo",
            PICNIC: "Picnic",
            PREVIA: "Previa",
            PRIUS: "Prius",
            "PRIUS+": "Prius+",
            "RAV 4": "RAV 4",
            STARLET: "Starlet",
            SUPRA: "Supra",
            URBANCRUISER: "Urban Cruiser",
            VERSO: "Verso",
            YARIS: "Yaris"
        }
    },
    TRIUMPH: {
        displayName: "Triumph",
        models: {
            STAG: "Stag",
            TR3A: "TR3A",
            TR4A: "TR4A"
        }
    },
    TVR: {
        displayName: "TVR",
        models: {
            CERBERA: "Cerbera",
            CHIMAERA: "Chimaera",
            GRIFFITH: "Griffith",
            SAGARIS: "Sagaris",
            "T350 C": "T350 C",
            "T350 T": "T350 T",
            "T400 R": "T400 R",
            "T440 R": "T440 R",
            TAMORA: "Tamora",
            TUSCAN: "Tuscan",
            TYPHON: "Typhon"
        }
    },
    VAUXHALL: {
        displayName: "Vauxhall",
        models: {
            ADAM: "Adam",
            AGILA: "Agila",
            AMPERA: "Ampera",
            ANTARA: "Antara",
            ASTRA: "Astra",
            CALIBRA: "Calibra",
            CASCADA: "Cascada",
            "COMBO TOUR": "Combo Tour",
            CORSA: "Corsa",
            "CROSSLAND X": "Crossland X",
            FRONTERA: "Frontera",
            "GRANDLAND X": "Grandland X",
            INSIGNIA: "Insignia",
            MERIVA: "Meriva",
            MOKKA: "Mokka",
            MONARO: "Monaro",
            MONTEREY: "Monterey",
            OMEGA: "Omega",
            SIGNUM: "Signum",
            SINTRA: "Sintra",
            TIGRA: "Tigra",
            VECTRA: "Vectra",
            VIVA: "Viva",
            VIVARO: "Vivaro",
            VX220: "VX220",
            VXR8: "VXR8",
            ZAFIRA: "Zafira"
        }
    },
    VOLKSWAGEN: {
        displayName: "Volkswagen",
        models: {
            AMAROK: "Amarok",
            ARTEON: "Arteon",
            BEETLE: "Beetle",
            BORA: "Bora",
            "CADDY CAMPER": "Caddy Camper",
            "CADDY LIFE": "Caddy Life",
            "CADDY MAXI LIFE": "Caddy Maxi Life",
            CALIFORNIA: "California",
            CARAVELLE: "Caravelle",
            CC: "CC",
            CORRADO: "Corrado",
            EOS: "Eos",
            FOX: "Fox",
            GOLF: "Golf",
            JETTA: "Jetta",
            LUPO: "Lupo",
            PASSAT: "Passat",
            PHAETON: "Phaeton",
            POLO: "Polo",
            SCIROCCO: "Scirocco",
            SHARAN: "Sharan",
            TIGUAN: "Tiguan",
            TOUAREG: "Touareg",
            TOURAN: "Touran",
            "T-ROC": "T-Roc",
            UP: "up!"
        }
    },
    VOLVO: {
        displayName: "Volvo",
        models: {
            "440": "440",
            "460": "460",
            "480": "480",
            "850": "850",
            "940": "940",
            "960": "960",
            C30: "C30",
            C70: "C70",
            "CROSS COUNTRY": "Cross Country",
            S40: "S40",
            S60: "S60",
            "S60 R": "S60 R",
            S70: "S70",
            S80: "S80",
            S90: "S90",
            V40: "V40",
            V50: "V50",
            V60: "V60",
            V70: "V70",
            V90: "V90",
            XC40: "XC40",
            XC60: "XC60",
            XC70: "XC70",
            XC90: "XC90"
        }
    },
    WESTFIELD: {
        displayName: "Westfield",
        models: {
            ROADSTER: "Roadster",
            SEI: "SEi",
            SPORT: "Sport"
        }
    }
};
export default data;
