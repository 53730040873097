import { Env, readFromEnv } from "@cinch-nx/environments";
import { create } from "zustand";
import axios from "axios";
// types
export * from "./lib/types";
// hooks
export * from "./lib/hooks";
// endpoints
const USER_PROFILE_URL = readFromEnv(Env.ProfileServiceUrl);
export const FAVOURITES_URL = `${USER_PROFILE_URL}/favourites`;
export const useFavouritesStore = create((set)=>({
        addFavourite: async (vehicleId)=>{
            const addFavouritesReq = await axios.post(FAVOURITES_URL, {
                vehicleId
            });
            set(()=>({
                    favourites: addFavouritesReq.data.favouriteVehicles
                }));
        },
        removeFavourite: async (vehicleId)=>{
            const removeFavouritesReq = await axios.delete(FAVOURITES_URL, {
                data: {
                    vehicleId
                }
            });
            set(()=>({
                    favourites: removeFavouritesReq.data.favouriteVehicles
                }));
        },
        fetchFavourites: async (withVehicleData)=>{
            try {
                const favouritesReq = await axios.get(`${FAVOURITES_URL}?withVehicleData=${withVehicleData ? "true" : "false"}`);
                set(()=>({
                        favourites: favouritesReq.data.favouriteVehicles,
                        favouritesError: undefined,
                        fetchFavouritesStatus: "valid"
                    }));
            } catch (error) {
                let errorMessage = "Unable to fetch favourites";
                if (axios.isAxiosError(error)) {
                    errorMessage = `Favourites error: ${error.status}, ${error.message}`;
                }
                set(()=>({
                        favouritesError: errorMessage,
                        fetchFavouritesStatus: "invalid"
                    }));
            }
        },
        favourites: [],
        favouritesError: undefined,
        fetchFavouritesStatus: "pending"
    }));
