import axios from "axios";
import { Env, readFromEnv } from "@cinch-nx/environments";
export const getVehiclesApi = (params)=>{
    const searchService = axios.create({
        baseURL: readFromEnv(Env.SearchServiceUrl)
    });
    return searchService.request({
        url: `/vehicles?vehicleIds=${params.vehicleIds}&excludeUnavailable=false&queryOnly=true`,
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    });
};
