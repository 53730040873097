export var Actions;
(function(Actions) {
    Actions["SELECT_MAKE_OPTION"] = "Select make option";
    Actions["SELECT_MODEL_OPTION"] = "Select model option";
    Actions["CLICK_MONTHLY_PRICE_RADIO"] = "Click monthly price radio button";
    Actions["CLICK_TOTAL_PRICE_RADIO"] = "Click total price radio button";
    Actions["SELECT_MONTHLY_PRICE_OPTION"] = "Select max monthly price option";
    Actions["SELECT_MAX_PRICE_OPTION"] = "Select max total price option";
    Actions["CLICK_SEARCH_CTA"] = "Click Search CTA";
    Actions["CLICK_TRUSTPILOT"] = "Click Trustpilot";
    Actions["CLICK_USP_LINK"] = "Click USP link";
    Actions["CLICK_CAR_REVIEW_CTA_PREFOOTER"] = "Click car review CTA prefooter";
    Actions["CLICK_HMC_LINK_IN_FILTERS_SECTION"] = "Click HMC link in filters section";
})(Actions || (Actions = {}));
