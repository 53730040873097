import { EventActions, EventCategories, EventNames } from "./return-to-checkout-modal.types";
import { TrackingEventTypes } from "./types/analytics";
export const useReturnToCheckoutModalTracking = (trackEvent)=>{
    const resumeCheckoutOpenedModal = (section)=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.modalOpened,
                label: "checkout-resume",
                section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - Modal Opened",
            data: {
                ...evt,
                modalState: section
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    const resumeCheckoutClosedModal = (section)=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.modalClosed,
                label: "checkout-resume",
                section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - Modal Closed",
            data: {
                ...evt,
                modalState: section
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    const resumeCheckoutReturnedToCheckout = (section)=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.returnToCheckout,
                label: "checkout-resume",
                section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - User Clicked Modal CTA",
            data: {
                ...evt,
                modalState: section
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    const clickModalVehicleImage = ()=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.image,
                action: EventActions.deadClick,
                label: "checkout-return-car-image"
            }
        };
        trackEvent({
            eventName: "Return to Checkout - User Clicked Vehicle Image",
            data: {
                ...evt
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    const iDoNotWantThisCarEvent = ()=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.iDoNotWantThisCar,
                label: "checkout-resume-modal"
            }
        };
        trackEvent({
            eventName: "Return to Checkout modal - User Clicked I do not want this car",
            data: {
                ...evt
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    const returnToCheckoutModalEvent = ()=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.returnToCheckout,
                label: "checkout-resume-modal"
            }
        };
        trackEvent({
            eventName: "Return to Checkout modal - User Clicked Return to Checkout",
            data: {
                ...evt
            },
            type: TrackingEventTypes.ADOBE
        });
    };
    return {
        resumeCheckoutOpenedModal,
        resumeCheckoutClosedModal,
        resumeCheckoutReturnedToCheckout,
        clickModalVehicleImage,
        returnToCheckoutModalEvent,
        iDoNotWantThisCarEvent
    };
};
