export const BodyTypeEnum = {
    Coupe: "COUPE",
    Estate: "ESTATE",
    Hatchback: "HATCHBACK",
    Saloon: "SALOON",
    Suv: "SUV"
};
export const DriveTypeEnum = {
    FourWheelDrive: "Four-wheel drive",
    FrontWheelDrive: "Front-wheel drive",
    RearWheelDrive: "Rear-wheel drive"
};
export const FuelTypeEnum = {
    Diesel: "Diesel",
    Electric: "Electric",
    Petrol: "Petrol",
    PetrolHybrid: "Petrol hybrid",
    PetrolPlugInHybrid: "Petrol plug-in hybrid"
};
export const QuoteType = {
    HP: "hp",
    Pcp: "pcp"
};
export const StockType = {
    Marketplace: "marketplace",
    PartnerSourced: "partner_sourced"
};
export const TransmissionTypeEnum = {
    Automatic: "AUTOMATIC",
    Manual: "MANUAL"
};
export const UpdatedBy = {
    FinanceQuoteComponentHPQuoteUpdated: "FinanceQuoteComponent.HPQuoteUpdated",
    FinanceQuoteComponentPCPQuoteUpdated: "FinanceQuoteComponent.PCPQuoteUpdated",
    MediaVehicleImageDataUpdated: "Media.vehicleImageDataUpdated",
    VehicleReservationsVehicleReserved: "VehicleReservations.VehicleReserved"
};
