import { useEffect, useState } from "react";
import { getCookieConsentPreferences, hasCookieConsentBeenSet, setCookieConsentPreferences } from "../utils/cookies";
export const defaultCookiePreferences = {
    advertising: false,
    analytics: false,
    functional: false
};
export function useCookiePreferences(setLoaded) {
    const [cookiePreferences, setCookiePreferencesState] = useState();
    useEffect(()=>{
        const cookie = getCookieConsentPreferences();
        if (cookie) {
            setCookiePreferencesState(cookie);
        } else {
            setCookiePreferencesState(defaultCookiePreferences);
        }
        setLoaded === null || setLoaded === void 0 ? void 0 : setLoaded(true);
    }, []);
    const setCookiePreferences = (state)=>{
        setCookiePreferencesState(state);
        setCookieConsentPreferences(state);
    };
    return {
        cookiePreferences: cookiePreferences,
        setCookiePreferences,
        isCookieConsentSet: hasCookieConsentBeenSet()
    };
}
