export const EnvironmentFromFile = {
    Fred: "FRED",
    IDontWantThisCar: "I_DONT_WANT_THIS_CAR",
    FomoExperimentId: "FOMO_EXPERIMENT_ID",
    RequestCallbackExperimentId: "REQUEST_CALLBACK_EXPERIMENT_ID",
    SSRProdTest: "SSR_PROD_TEST",
    ClientProdTest: "CLIENT_PROD_TEST",
    HomepageSSRTest: "HOMEPAGE_SSR_TEST",
    NextBestActionExperimentId: "NEXT_BEST_ACTION_EXPERIMENT_ID",
    BuyNowCTAMessaging: "BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID",
    SearchMultipleImagesV2ExperimentId: "SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID"
};
export const Env = {
    ...EnvironmentFromFile
};
