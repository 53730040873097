export const searchFiltersTestState = {
    make: [
        "FORD"
    ],
    model: [
        "FIESTA"
    ],
    trim: [
        "tiTaNIUm",
        "active 1"
    ],
    fromPrice: [
        "7000"
    ],
    toPrice: [
        "15000"
    ],
    fromYear: [
        "2014"
    ],
    toYear: [
        "2020"
    ],
    mileage: [
        "20000"
    ],
    fuelType: [
        "petrol",
        "electric"
    ],
    colour: [
        "black",
        "white"
    ],
    bodyType: [
        "hatchback",
        "suv"
    ],
    gearbox: [
        "manual"
    ],
    fromEngineSize: [
        "1150"
    ],
    toEngineSize: [
        "1649"
    ],
    doors: [
        "2"
    ],
    seats: [
        "4"
    ],
    driveType: [
        "front-wheel drive"
    ],
    store: [],
    tags: [],
    features: []
};
export const nonFiltersParamsTestState = {
    sortingCriteria: 3,
    useMonthly: false,
    pageNumber: 1,
    pageSize: 32
};
export const mockedMakeModelVariantFacets = {
    makes: [
        {
            name: "Audi",
            doc_count: 224,
            models: [
                {
                    name: "A1",
                    doc_count: 17,
                    variants: []
                }
            ]
        },
        {
            name: "Ford",
            doc_count: 324,
            models: [
                {
                    name: "Fiesta",
                    doc_count: 10,
                    variants: [
                        {
                            name: "Active 1",
                            doc_count: 2
                        }
                    ]
                },
                {
                    name: "B-Max",
                    doc_count: 3,
                    variants: [
                        {
                            name: "Titanium",
                            doc_count: 2
                        }
                    ]
                }
            ]
        }
    ]
};
