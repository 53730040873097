import { datadogLogs } from "@datadog/browser-logs";
import { getEnvironmentFromURL, hasWindow } from "../utils";
export const initDataDogLogging = ({ serviceName: service , datadogRumClientToken: clientToken  })=>{
    if (hasWindow) {
        const currentEnv = getEnvironmentFromURL(window.location.hostname);
        datadogLogs.init({
            clientToken,
            site: "datadoghq.eu",
            forwardErrorsToLogs: true,
            service,
            sessionSampleRate: 100,
            silentMultipleInit: true
        });
        datadogLogs.setGlobalContextProperty("env", currentEnv);
    }
};
