import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { getAnalyticIDs } from "@cinch-labs/shared-util";
export const getSegmentUserId = ()=>{
    var ref, ref1;
    return ((ref = window.analytics) === null || ref === void 0 ? void 0 : ref.user().id()) ?? ((ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.user().anonymousId()) ?? "";
};
export const datadogMiddleware = ({ payload , next  })=>{
    if (window._DATADOG_SYNTHETICS_BROWSER) return;
    const userId = getSegmentUserId();
    datadogRum.setGlobalContextProperty("user", {
        id: userId
    });
    next(payload);
};
export const excludedPagesMiddleware = ({ payload , next  })=>{
    const excludedPages = [
        "/auth-callback",
        "/silent-renew"
    ];
    if (excludedPages.includes(payload.obj.context.page.path)) return;
    next(payload);
};
export const unauthenticatedMiddleware = ({ payload , next  })=>{
    var ref;
    // Have to access this via window.analytics rather than payload because
    // the payload doesn't detail if a user is authed or not
    if ((ref = window.analytics) === null || ref === void 0 ? void 0 : ref.user().id()) return next(payload);
    const integrationWithPermissions = {
        Zendesk: false,
        "Customer.io": false
    };
    const updatedPayload = {
        ...payload,
        obj: {
            ...payload.obj,
            integrations: {
                ...integrationWithPermissions
            }
        }
    };
    next(updatedPayload);
};
export const configureMiddleware = ()=>{
    var ref, ref1, ref2;
    (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.addSourceMiddleware(datadogMiddleware);
    (ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.addSourceMiddleware(excludedPagesMiddleware);
    (ref2 = window.analytics) === null || ref2 === void 0 ? void 0 : ref2.addSourceMiddleware(unauthenticatedMiddleware);
};
export const identify = ({ userId , data  })=>{
    try {
        // const email = readEmailFromSessionStorage()
        const identifyData = {
            ...data
        };
        if (userId) {
            var ref;
            (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.identify(userId, identifyData);
        } else {
            var ref1;
            (ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.identify(identifyData);
        }
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.identify() failed", {
            userId,
            data,
            error
        });
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const track = (eventName, data)=>{
    if (!eventName || eventName.length === 0) return;
    const { adobeID , gaID  } = getAnalyticIDs();
    const analyticsContext = {
        analyticIds: {
            adobeID,
            gaID
        }
    };
    // const email = readEmailFromSessionStorage()
    const options = {
        context: {
            ...analyticsContext
        }
    };
    try {
        var ref;
        (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.track(eventName, {
            ...data
        }, options);
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.track() failed", {
            eventName,
            data,
            error
        });
    }
};
export const reset = ()=>{
    try {
        var ref;
        (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.reset();
    } catch  {
        datadogLogs.logger.error("call to window.analytics.reset() failed");
    }
};
export const page = (name, data)=>{
    if (!name) return;
    try {
        var ref;
        (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.page(name, data ?? {}, {
            integrations: {
                Zendesk: false
            }
        });
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.page() failed", {
            name,
            data,
            error
        });
    }
};
