import { JsonLDType } from "../json-ld-script";
export const formatBreadcrumbListSchema = (breadcrumbs)=>({
        "@type": JsonLDType.BreadcrumbList,
        itemListElement: breadcrumbs.map((crumb, index)=>({
                "@type": JsonLDType.ListItem,
                position: index + 1,
                name: crumb.text,
                item: `${process.env.STAGE_URL}${crumb.url}`
            }))
    });
export const formatFaqPageSchema = (faqs)=>({
        "@type": JsonLDType.FaqPage,
        mainEntity: faqs.map(({ question , summary  })=>({
                "@type": JsonLDType.Question,
                name: question,
                acceptedAnswer: {
                    "@type": JsonLDType.Answer,
                    text: summary
                }
            }))
    });
export const formatVideoSchema = (video)=>({
        "@type": JsonLDType.VideoObject,
        name: video.title,
        description: video.description,
        thumbnailUrl: video.thumbnail ? [
            video.thumbnail.filename
        ] : undefined,
        duration: video.duration,
        uploadDate: video.uploadDate
    });
export const formatHowToSchema = (howto)=>{
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    return {
        "@type": JsonLDType.HowTo,
        name: (ref = howto[0]) === null || ref === void 0 ? void 0 : ref.name,
        image: (ref1 = howto[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.image) === null || ref2 === void 0 ? void 0 : ref2.map((p)=>({
                "@type": "ImageObject",
                url: p.url,
                height: p.height,
                width: p.width
            })),
        video: (ref3 = howto[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.video) === null || ref4 === void 0 ? void 0 : ref4.map((p)=>{
            var ref;
            return {
                "@type": "VideoObject",
                name: p.name,
                description: p.description,
                thumbnailUrl: p.thumbnailUrl,
                contentUrl: p.contentUrl,
                embedUrl: p.embedUrl,
                uploadDate: p.uploadDate,
                duration: p.duration,
                hasPart: (ref = p.hasPart) === null || ref === void 0 ? void 0 : ref.map((element)=>({
                        "@type": "Clip",
                        "@id": element.id,
                        name: element.name,
                        startOffset: element.startOffset,
                        endOffset: element.endOffset,
                        url: element.url
                    }))
            };
        }),
        step: (ref5 = howto[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.step) === null || ref6 === void 0 ? void 0 : ref6.map((p)=>{
            var ref, ref1, ref2, ref3;
            return {
                url: p.url,
                name: p.name,
                "@type": "HowToStep",
                image: {
                    "@type": "ImageObject",
                    url: (ref = p === null || p === void 0 ? void 0 : p.image[0]) === null || ref === void 0 ? void 0 : ref.url,
                    height: (ref1 = p.image[0]) === null || ref1 === void 0 ? void 0 : ref1.height,
                    width: (ref2 = p.image[0]) === null || ref2 === void 0 ? void 0 : ref2.width
                },
                itemListElement: (ref3 = p.itemListElement) === null || ref3 === void 0 ? void 0 : ref3.map((element)=>({
                        "@type": "HowToDirection",
                        text: element.text
                    }))
            };
        }),
        supply: (ref7 = howto[0]) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.supply) === null || ref8 === void 0 ? void 0 : ref8.map((element)=>({
                "@type": "HowToSupply",
                name: element.name
            })),
        tool: (ref9 = howto[0]) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.tool) === null || ref10 === void 0 ? void 0 : ref10.map((element)=>({
                "@type": "HowToTool",
                name: element.name
            }))
    };
};
export const formatReviewToSchema = (review, publishedDate, prosAndCons)=>{
    var ref, ref1, ref2;
    return {
        "@type": "Vehicle",
        name: `${review === null || review === void 0 ? void 0 : review.make} ${review === null || review === void 0 ? void 0 : review.model}`,
        image: (review === null || review === void 0 ? void 0 : (ref = review.gallery) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.image) === null || ref2 === void 0 ? void 0 : ref2.filename) || "",
        review: {
            "@type": "Review",
            reviewRating: {
                "@type": "Rating",
                ratingValue: `${review === null || review === void 0 ? void 0 : review.starRating}`,
                bestRating: `10`,
                worstRating: `1`,
                author: {
                    "@type": "Person",
                    name: `${review === null || review === void 0 ? void 0 : review.authorName}` || `cinch`
                }
            },
            positiveNotes: {
                "@type": "ItemList",
                itemListElement: prosAndCons.pros.map((items, index)=>({
                        "@type": "ListItem",
                        position: index + 1,
                        name: items.text
                    }))
            },
            negativeNotes: {
                "@type": "ItemList",
                itemListElement: prosAndCons.cons.map((items, index)=>({
                        "@type": "ListItem",
                        position: index + 1,
                        name: items.text
                    }))
            },
            author: {
                "@type": "Person",
                name: (review === null || review === void 0 ? void 0 : review.authorName) || "cinch"
            },
            publisher: {
                "@type": "Organization",
                name: "cinch"
            },
            reviewBody: review === null || review === void 0 ? void 0 : review.snippet,
            datePublished: publishedDate.split("T")[0]
        }
    };
};
export const formatTimeTo24Hour = (storeTime)=>{
    if (storeTime === "Closed") return storeTime;
    const [time, meridiem] = storeTime.split(" ");
    const [hour, minute] = time.split(":");
    const hour24 = meridiem === "AM" ? hour : Number.parseInt(hour) + 12;
    return `${hour24}:${minute}`;
};
export const formatOpeningHours = (openingHoursList)=>openingHoursList.map((openingHours)=>{
        const { openingTime , closingTime , daysOfWeek  } = openingHours;
        const days = daysOfWeek.includes(" - ") ? daysOfWeek.split(" ").map((day)=>day.slice(0, 2)).join("") : daysOfWeek.slice(0, 2);
        if (openingTime === "Closed" || closingTime === "Closed") return `${days} ${openingTime}`;
        return `${days} ${formatTimeTo24Hour(openingTime)}-${formatTimeTo24Hour(closingTime)}`;
    });
export const formatStoreToSchema = ({ storeSlug , storeAddress , mapInformation , phoneNumbers , openingHoursList , storeImageUrl  })=>{
    const { latitude , longitude  } = mapInformation[0];
    const { phoneNumber  } = phoneNumbers[0];
    return {
        "@type": JsonLDType.AutomotiveBusiness,
        name: `cinch store ${storeSlug}`,
        image: storeImageUrl,
        "@id": `https://cinch.co.uk/store/${storeSlug}`,
        url: `https://cinch.co.uk/store/${storeSlug}`,
        telephone: phoneNumber,
        address: {
            "@type": "PostalAddress",
            streetAddress: `${storeAddress.addressLine1}, ${storeAddress.addressLine2}`,
            addressLocality: storeAddress.townOrCity,
            postalCode: storeAddress.postCode,
            addressCountry: "GB"
        },
        geo: {
            "@type": "GeoCoordinates",
            latitude,
            longitude
        },
        openingHours: formatOpeningHours(openingHoursList),
        contactPoint: {
            "@type": "ContactPoint",
            telephone: phoneNumber,
            contactType: "customer service",
            areaServed: "GB",
            availableLanguage: "English"
        },
        sameAs: [
            "https://www.facebook.com/cinchuk",
            "https://twitter.com/cinchuk",
            "https://www.instagram.com/cinchuk"
        ]
    };
};
