export default {
    addToCompare: "add-to-compare",
    addToFavourite: "add-to-favourite",
    appInstallAccepted: "app-install-accepted",
    appInstallRejected: "app-install-rejected",
    appInstalled: "app-installed",
    dealerPhoneCalled: "dealer-phone-called",
    dealerPhoneClicked: "dealer-phone-clicked",
    downloadPartnerData: "download-partner-data",
    downloadUserData: "download-user-data",
    findCarIcons: "find-car-icons",
    hideMapClicked: "hide-map-clicked",
    messageCallbackClicked: "callback-popup-opened",
    messageCallbackError: "callback-request-error",
    messageCallbackSuccess: "callback-request-success",
    messageDealerClicked: "message-dealer-clicked",
    messageDealerClickedPhoneModal: "message-dealer-clicked-phone-modal",
    messageDealerError: "message-dealer-error",
    messageDealerSuccess: "message-dealer-success",
    openMarketingPreferencesClicked: "open-marketing-preferences-clicked",
    openProfileDetailsClicked: "open-profile-details-clicked",
    printAdvertClicked: "print-advert-clicked",
    registrationError: "registration-error",
    registrationSuccess: "registration-success",
    removeFromCompareBar: "remove-from-compare-bar",
    removeFromCompareCard: "remove-from-compare-card",
    removeFromFavourites: "remove-from-favourites",
    requestUserData: "request-user-data",
    showMapClicked: "show-map-clicked",
    siteExitCazoo: "site-exit-cazoo",
    siteExitExperian: "site-exit-experian",
    unregisteredUserAddToFavourite: "unregistered-user-add-to-favourite",
    vehicleDetailLoadError: "vehicle-detail-load-error",
    vehicleDetailLoadSuccess: "vehicle-detail-load-success",
    vehicleListing: "vehicle-listing",
    vehicleListingLoadSuccess: "vehicle-listing-load-success",
    vehicleListingSendImpressions: "vehicle-listing-send-impressions",
    visitWebsiteClicked: "visit-website-clicked"
};
