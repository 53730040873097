import { getCookieBannerMetrics, getCookieSettings } from "./cookies";
import * as TrackInteraction from "./track-interaction";
export const addEvent = ({ startTime , elementType , count , cookieEditorTime , cookiePreferences , settingsType  })=>{
    window.analytics && window.analytics.ready(()=>{
        TrackInteraction.cookieSettings(getCookieSettings(startTime, cookiePreferences, settingsType));
        TrackInteraction.cookieBannerMetrics(getCookieBannerMetrics(startTime, elementType, count));
        if (cookieEditorTime !== 0) {
            TrackInteraction.cookieEditorShown(cookieEditorTime);
        }
    });
};
