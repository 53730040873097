import { datadogRum } from "@datadog/browser-rum";
export const sendReservationError = (description, error, orderId)=>{
    var ref, ref1, ref2;
    return datadogRum.addError(description, {
        location: "reservation-service-nx",
        orderId,
        error: {
            message: (ref = error.response) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.message,
            status: (ref2 = error.response) === null || ref2 === void 0 ? void 0 : ref2.status,
            stack: error.stack
        }
    });
};
