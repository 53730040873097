export var CheckoutStage;
(function(CheckoutStage) {
    CheckoutStage["PersonalDetails"] = "PersonalDetails";
    CheckoutStage["PartExchange"] = "PartExchange";
    CheckoutStage["Finance"] = "Finance";
    CheckoutStage["DeliverySlots"] = "DeliverySlots";
    CheckoutStage["Extras"] = "Extras";
    CheckoutStage["CinchCare"] = "CinchCare";
    CheckoutStage["Payment"] = "Payment";
    CheckoutStage["SignUp"] = "SignUp";
})(CheckoutStage || (CheckoutStage = {}));
export var EventActions;
(function(EventActions) {
    EventActions["addCinchCare"] = "checkout - add cinchcare";
    EventActions["addCinchCareExtras"] = "added_to_order";
    EventActions["backToGuide"] = "back-to-guide";
    EventActions["backToGuideWithValuation"] = "back-to-guide-with-valuation";
    EventActions["backToBasket"] = "back-to-basket";
    EventActions["backToBasketWithValuation"] = "back-to-basket-with-valuation";
    EventActions["backToCar"] = "back-to-car";
    EventActions["checkoutAddCinchCare"] = "checkout-add-cinchcare";
    EventActions["cinchCareDirectDebit"] = "checkout - cinchcare Direct Debit";
    EventActions["cinchCareConfirmed"] = "checkout - cinchcare confirmed";
    EventActions["cinchCareOrderConfirmation"] = "cinchCare added to order";
    EventActions["close"] = "close";
    EventActions["closeDrawer"] = "close-drawer";
    EventActions["codeFailed"] = "code-failed";
    EventActions["codeSuccess"] = "code-success";
    EventActions["continueToCheckout"] = "continue-to-checkout";
    EventActions["error"] = "error";
    EventActions["expand"] = "expand";
    EventActions["find"] = "find";
    EventActions["financeAvailable"] = "finance-available";
    EventActions["financeQuote"] = "finance-available";
    EventActions["googleLogin"] = "google-login";
    EventActions["highValuation"] = "high-valuation";
    EventActions["modalOpened"] = "modal-opened";
    EventActions["modalClosed"] = "modal-closed";
    EventActions["moreMileageOptions"] = "more-mileage-options";
    EventActions["navigate"] = "navigate";
    EventActions["noPartExNoFinanceQuote"] = "no_partex-no_finance-available";
    EventActions["open"] = "open";
    EventActions["openDrawer"] = "open-drawer";
    EventActions["partexAvailable"] = "partex-available";
    EventActions["partExLanding"] = "part-ex-landing";
    EventActions["play"] = "play";
    EventActions["referAFriend"] = "refer-a-friend";
    EventActions["postcodeLookup"] = "postcode-lookup";
    EventActions["postcodeDropdown"] = "postcode-lookup-dropdown";
    EventActions["promotionalTerms"] = "promotional-terms";
    EventActions["remove"] = "remove";
    EventActions["removeCar"] = "remove-car";
    EventActions["removeFinance"] = "remove-finance";
    EventActions["removePartex"] = "remove-partex";
    EventActions["returnToCheckout"] = "return-to-checkout";
    EventActions["reviewAndPay"] = "checkout - review and pay";
    EventActions["search"] = "search";
    EventActions["select"] = "select";
    EventActions["start"] = "start";
    EventActions["submit"] = "submit";
    EventActions["thirdParty"] = "third-party";
    EventActions["valuationAvailable"] = "valuation-available";
    EventActions["tellMeMore"] = "tell-me-more";
    EventActions["ticked"] = "ticked";
    EventActions["unticked"] = "unticked";
    EventActions["view"] = "view";
    EventActions["continue"] = "continue";
    EventActions["deadClick"] = "deadClick";
    EventActions["dontWantCare"] = "i-do-not-want-this-car";
    EventActions["resumeModal"] = "checkout-resume-modal";
})(EventActions || (EventActions = {}));
export var EventCategories;
(function(EventCategories) {
    EventCategories["button"] = "button";
    EventCategories["checkout"] = "checkout";
    EventCategories["chevron"] = "chevron";
    EventCategories["cinchcare"] = "cinchcare";
    EventCategories["dropdown"] = "dropdown";
    EventCategories["form"] = "form";
    EventCategories["marker"] = "marker";
    EventCategories["page"] = "page";
    EventCategories["text"] = "text";
    EventCategories["tooltip"] = "tooltip";
    EventCategories["checkbox"] = "checkbox";
    EventCategories["panel"] = "panel";
    EventCategories["partexCheckbox"] = "partex-checkbox";
    EventCategories["view"] = "view";
    EventCategories["link"] = "link";
    EventCategories["box"] = "box";
    EventCategories["vaps"] = "vaps";
    EventCategories["video"] = "video";
    EventCategories["image"] = "image";
})(EventCategories || (EventCategories = {}));
export var EventNames;
(function(EventNames) {
    EventNames["click"] = "click";
    EventNames["error"] = "error";
    EventNames["load"] = "load";
    EventNames["scCheckout"] = "scCheckout";
    EventNames["impression"] = "impression";
})(EventNames || (EventNames = {}));
