import { create } from "zustand";
const useStore = create((set)=>({
        isPreferencesModalOpen: false,
        setIsPreferencesModalOpen: (arg)=>{
            const newState = {
                isPreferencesModalOpen: arg
            };
            set(()=>({
                    ...newState
                }));
        }
    }));
const isPreferencesModalOpenSelector = (state)=>state.isPreferencesModalOpen;
const setIsPreferencesModalOpenSelector = (state)=>state.setIsPreferencesModalOpen;
const useCookieModalOpen = ()=>{
    const isPreferencesModalOpen = useStore(isPreferencesModalOpenSelector);
    const setIsPreferencesModalOpen = useStore(setIsPreferencesModalOpenSelector);
    return {
        isPreferencesModalOpen,
        setIsPreferencesModalOpen
    };
};
export default useCookieModalOpen;
