/*
 * This will traverse up the DOM tree to check if the
 * selected / clicked on element `child`, is a child
 * of the Ref `parent`
 */ export const isChildOf = (child, parent)=>{
    while(child && parent){
        if (child === parent) return true;
        child = child.parentElement;
    }
    return false;
};
