import tracer from "dd-trace";
import { isTraceableEnvironment } from "./is-traceable-environment";
/**
 * Returns an instrumented version of a function that will create a span
 * with the supplied name when the function is called
 *
 * Unlike `startSpan`, this does not call the supplied function immediately
 *
 * On a non-traceable environment, this will return the supplied function
 */ export const traceFunction = (name, func)=>isTraceableEnvironment() ? tracer.wrap(name, func) : func;
