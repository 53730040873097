export const transmissionTypeData = [
    {
        label: "Automatic",
        value: "automatic",
        facetKeys: [
            "semi-auto",
            "automatic"
        ]
    },
    {
        label: "Manual",
        value: "manual"
    }
];
