import { identify, page, track } from "@cinch-labs/customer-data-segment";
import { isBrowser } from "@cinch-labs/shared-util";
import { create } from "zustand";
import { sendDigitalDataEvent, sendHotjarEvent, sendHotjarTag, sendHotjarTrigger } from "../data-layer/datalayer";
import { TrackingEventTypes } from "./types";
export const getCookie = ({ name  })=>{
    var ref;
    if (!isBrowser) {
        return undefined;
    }
    return ((ref = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)) === null || ref === void 0 ? void 0 : ref.pop()) ?? undefined;
};
export const getCookieConsentPreferences = (name)=>cookiePreferencesCookieParser(getCookie({
        name
    }));
const cookiePreferencesCookieParser = (cookieContents)=>cookieContents ? JSON.parse(cookieContents) : undefined;
export const useAnalytics = create((set, get)=>{
    return {
        eventsQueue: [],
        next: ()=>get().eventsQueue.shift(),
        hasAcceptedCookies: false,
        setHasAcceptedCookies: (accepted)=>set({
                hasAcceptedCookies: accepted
            }),
        hasLoadedScripts: false,
        setHasLoadedScripts: (loaded)=>set({
                hasLoadedScripts: loaded
            }),
        trackEvent: (event)=>set((state)=>({
                    eventsQueue: [
                        ...state.eventsQueue,
                        event
                    ]
                })),
        processEventsQueue: ()=>{
            const cookiePreferences = getCookieConsentPreferences("cp");
            if (!(cookiePreferences === null || cookiePreferences === void 0 ? void 0 : cookiePreferences.analytics)) {
                return;
            }
            while(get().eventsQueue.length > 0){
                const event = get().next();
                if (!event) {
                    return;
                }
                switch(event.type){
                    case TrackingEventTypes.IDENTIFY:
                        if (event.userId) {
                            identify({
                                userId: event.userId,
                                data: event.data || {}
                            });
                        } else {
                            identify({
                                data: event.data || {}
                            });
                        }
                        break;
                    case TrackingEventTypes.TRACK:
                        track(event.eventName, event.data);
                        break;
                    case TrackingEventTypes.ADOBE:
                        sendDigitalDataEvent(event.data);
                        break;
                    case TrackingEventTypes.PAGE:
                        page(event.eventName, event.data);
                        sendDigitalDataEvent(event.data);
                        break;
                    case TrackingEventTypes.HOTJAR_TAG:
                        sendHotjarTag(event.eventName);
                        break;
                    case TrackingEventTypes.HOTJAR_TRIGGER:
                        sendHotjarTrigger(event.eventName);
                        break;
                    case TrackingEventTypes.HOTJAR_EVENT:
                        sendHotjarEvent(event.eventName);
                        break;
                    case TrackingEventTypes.PAGE_NO_ADOBE:
                        page(event.eventName, event.data);
                        break;
                }
            }
        }
    };
});
