import { formatEngineSizeInLitres, formatFuelType } from "./format-vehicle-data";
const sentenceCase = (input)=>input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
export const getVehicleTags = (vehicle)=>[
        {
            label: "Body Type",
            value: vehicle.bodyType
        },
        {
            label: "Colour",
            value: sentenceCase(vehicle.colour)
        },
        {
            label: "Engine Size",
            value: formatEngineSizeInLitres(vehicle.engineSize || vehicle.engineCapacityCc)
        },
        {
            label: "Fuel Type",
            value: formatFuelType(vehicle.fuelType)
        },
        {
            label: "Mileage",
            value: `${Intl.NumberFormat().format(vehicle.mileage)} miles`
        },
        {
            label: "Transmission Type",
            value: sentenceCase(vehicle.transmissionType)
        },
        {
            label: "Vehicle Year",
            value: `${vehicle.vehicleYear}`
        },
        {
            label: "Doors",
            value: `${vehicle.doors} Doors`
        },
        {
            label: "Seats",
            value: `${vehicle.seats} Seats`
        }
    ].filter(({ value  })=>value !== "-").filter(({ value  })=>!!value);
