export const ANY_PRICE = -1;
export const payFullRange = [
    {
        label: "\xa35,500",
        value: 5500,
        rangeKey: "5500-6000"
    },
    {
        label: "\xa36,000",
        value: 6000,
        rangeKey: "6000-6500"
    },
    {
        label: "\xa36,500",
        value: 6500,
        rangeKey: "6500-7000"
    },
    {
        label: "\xa37,000",
        value: 7000,
        rangeKey: "7000-7500"
    },
    {
        label: "\xa37,500",
        value: 7500,
        rangeKey: "7500-8000"
    },
    {
        label: "\xa38,000",
        value: 8000,
        rangeKey: "8000-8500"
    },
    {
        label: "\xa38,500",
        value: 8500,
        rangeKey: "8500-9000"
    },
    {
        label: "\xa39,000",
        value: 9000,
        rangeKey: "9000-9500"
    },
    {
        label: "\xa39,500",
        value: 9500,
        rangeKey: "9500-10000"
    },
    {
        label: "\xa310,000",
        value: 10000,
        rangeKey: "10000-11000"
    },
    {
        label: "\xa311,000",
        value: 11000,
        rangeKey: "11000-12000"
    },
    {
        label: "\xa312,000",
        value: 12000,
        rangeKey: "12000-13000"
    },
    {
        label: "\xa313,000",
        value: 13000,
        rangeKey: "13000-14000"
    },
    {
        label: "\xa314,000",
        value: 14000,
        rangeKey: "14000-15000"
    },
    {
        label: "\xa315,000",
        value: 15000,
        rangeKey: "15000-20000"
    },
    {
        label: "\xa320,000",
        value: 20000,
        rangeKey: "20000-25000"
    },
    {
        label: "\xa325,000",
        value: 25000,
        rangeKey: "25000-30000"
    },
    {
        label: "\xa330,000",
        value: 30000,
        rangeKey: "30000-40000"
    },
    {
        label: "\xa340,000",
        value: 40000,
        rangeKey: "40000-50000"
    },
    {
        label: "\xa350,000",
        value: 50000,
        rangeKey: "50000-*"
    }
];
export const payMonthlyRange = [
    {
        label: "\xa350 p/m",
        value: 50,
        rangeKey: "50-100"
    },
    {
        label: "\xa3100 p/m",
        value: 100,
        rangeKey: "100-150"
    },
    {
        label: "\xa3150 p/m",
        value: 150,
        rangeKey: "150-200"
    },
    {
        label: "\xa3200 p/m",
        value: 200,
        rangeKey: "200-250"
    },
    {
        label: "\xa3250 p/m",
        value: 250,
        rangeKey: "250-300"
    },
    {
        label: "\xa3300 p/m",
        value: 300,
        rangeKey: "300-350"
    },
    {
        label: "\xa3350 p/m",
        value: 350,
        rangeKey: "350-400"
    },
    {
        label: "\xa3400 p/m",
        value: 400,
        rangeKey: "400-450"
    },
    {
        label: "\xa3450 p/m",
        value: 450,
        rangeKey: "450-500"
    },
    {
        label: "\xa3500 p/m",
        value: 500,
        rangeKey: "500-550"
    },
    {
        label: "\xa3550 p/m",
        value: 550,
        rangeKey: "550-600"
    },
    {
        label: "\xa3600 p/m",
        value: 600,
        rangeKey: "600-650"
    },
    {
        label: "\xa3650 p/m",
        value: 650,
        rangeKey: "650-700"
    },
    {
        label: "\xa3700 p/m",
        value: 700,
        rangeKey: "700-750"
    },
    {
        label: "\xa3750 p/m",
        value: 750,
        rangeKey: "750-800"
    },
    {
        label: "\xa3800 p/m",
        value: 800,
        rangeKey: "800-850"
    },
    {
        label: "\xa3850 p/m",
        value: 850,
        rangeKey: "850-900"
    },
    {
        label: "\xa3900 p/m",
        value: 900,
        rangeKey: "900-950"
    },
    {
        label: "\xa3950 p/m",
        value: 950,
        rangeKey: "950-*"
    }
];
