import { STORYBLOK_BFF_URL } from "../../util/api";
import { getStoryBySlugUrlParams } from "./schema";
import { setTag, tagError, traceFunction } from "@core/datadog-server";
const hasStoryblokStory = (input)=>{
    if (!input || !Object.prototype.hasOwnProperty.call(input, "story")) {
        return false;
    }
    return true;
};
async function getStoryBySlugFn(slug, params, // eslint-disable-next-line unicorn/no-object-as-default-parameter
options = {
    strict: true
}) {
    const urlParams = getStoryBySlugUrlParams.parse(params);
    urlParams.append("slug", slug);
    const url = `${STORYBLOK_BFF_URL}/story-by-slug?${urlParams.toString()}`;
    try {
        const request = await fetch(url);
        setTag("request.url", url);
        setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
        setTag("request.method", "GET");
        setTag("request.status", request.status);
        setTag("request.content-length", request.headers.get("content-length") ?? "0");
        const response = await request.json();
        if (!hasStoryblokStory(response)) {
            throw new Error("Story Response has no story property.");
        }
        return {
            ...response.story,
            rels: response.rels
        };
    } catch (error) {
        if (!options.strict) {
            return null;
        }
        if (error instanceof Error || typeof error === "string") {
            tagError(error);
        }
        let errorMessage = error || "Unknown Error";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        throw new Error(`'story-by-slug' endpoint error
        Original Error: "${errorMessage}"
        Slug: "${slug}",
        Failing Url: "${url}"
    `);
    }
}
export const getStoryBySlug = traceFunction("storyblok.getStoryBySlug", getStoryBySlugFn);
