import { getToken } from "@cinch-labs/shared-util";
import { constants } from "@cinch-nx/data-search";
import { Env, readFromEnv } from "@cinch-nx/environments";
import axios from "axios";
export const UNAUTHORISED_ENDPOINTS = [
    constants.VEHICLE_SEARCH_URL
];
axios.interceptors.request.use((config)=>{
    const token = getToken({
        authKey: readFromEnv(Env.AuthKey),
        legacyAuthKey: readFromEnv(Env.LegacyAuthKey)
    });
    if (!UNAUTHORISED_ENDPOINTS.some((endpoint)=>{
        var ref;
        return (ref = config.url) === null || ref === void 0 ? void 0 : ref.includes(endpoint);
    })) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token === null || token === void 0 ? void 0 : token.access_token}`
        };
    }
    return config;
});
axios.interceptors.response.use(undefined, (error)=>Promise.reject(error));
export const fetcher = (url)=>axios.get(url).then((res)=>res.data);
