import { designTokens } from "@cinch-labs/design-system";
const isTouchAvailable = ()=>{
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch  {
        return false;
    }
};
export function isMobile() {
    return window && window.matchMedia && !window.matchMedia(designTokens.customMedia.s).matches && isTouchAvailable();
}
