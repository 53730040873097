import { TrackingEventTypes } from "@cinch-nx/shared-analytics";
export const compareCtaClickEvent = (comparisonVehicles)=>({
        eventName: "compare-cta-click",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "Compare",
                action: "Compare Cars",
                label: "Clickthrough",
                value: comparisonVehicles.map((vehicle)=>`${vehicle.vehicleTitle}`).join(",")
            }
        }
    });
export const compareVehicleRemovalEvent = (vehicleMake, vehicleModel, comparisonVehicles)=>({
        eventName: "compare-vehicle-removal",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "Compare",
                action: "Remove Vehicle - compare bar",
                label: vehicleModel,
                type: vehicleMake,
                value: comparisonVehicles.length - 1
            }
        }
    });
export const compareBarDrawerInteractionEvent = (open)=>({
        eventName: "compare-bar-drawer-interaction",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "Compare",
                action: open ? "Open Drawer" : "Close Drawer",
                label: "",
                type: "",
                value: ""
            }
        }
    });
export const compareMaxNumberOfVehicles = (maxNumberOfItems)=>({
        eventName: "compare-max-number-of-vehicles",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "Compare",
                action: "Max cars alert",
                value: maxNumberOfItems,
                type: "",
                label: ""
            }
        }
    });
export const clickCompareVehicle = ({ action , make , model , value  })=>({
        eventName: "click-compare-vehicle",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "Compare",
                action,
                type: make,
                label: model,
                value
            }
        }
    });
