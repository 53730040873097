export const seatsData = [
    {
        label: "2 seats",
        value: "2"
    },
    {
        label: "4 seats",
        value: "4"
    },
    {
        label: "5 seats",
        value: "5"
    },
    {
        label: "7 seats",
        value: "7"
    },
    {
        label: "8 seats",
        value: "8"
    }
];
