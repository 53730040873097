import { constants } from "@cinch-nx/data-search";
import { fetcher, hasTokenExpired, useUserStore } from "@cinch-nx/data-user";
import useSWR from "swr";
import { FAVOURITES_URL } from "../../index";
export const useFavouriteVehicles = (withVehicleData = false)=>{
    var ref;
    const { setStatus , status  } = useUserStore();
    const { data: favouritesData , error: favouritesError , mutate: mutateFavourites  } = useSWR(status === "valid" && FAVOURITES_URL, fetcher);
    const directFavouritesTokenExpired = hasTokenExpired(favouritesError);
    if (directFavouritesTokenExpired) {
        setStatus("expired");
    }
    const favouritesAmount = (favouritesData === null || favouritesData === void 0 ? void 0 : (ref = favouritesData.favouriteVehicles) === null || ref === void 0 ? void 0 : ref.length) ?? 0;
    const { data: vehicleData , error: vehicleDetailsError  } = useSWR(()=>{
        return withVehicleData && !!favouritesAmount ? `${constants.VEHICLE_SEARCH_URL}?vehicleIds=${favouritesData === null || favouritesData === void 0 ? void 0 : favouritesData.favouriteVehicles.map(({ vehicleId  })=>vehicleId).join(",")}&excludeUnavailable=false&queryOnly=true` : null;
    }, fetcher);
    const favouriteVehiclesTokenExpired = hasTokenExpired(favouritesError);
    if (favouriteVehiclesTokenExpired) {
        setStatus("expired");
    }
    return {
        favouritesData,
        favouritesAmount,
        favouritesError,
        vehicleData,
        vehicleDetailsError,
        mutateFavourites
    };
};
