import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getCinchCareEligibilityPeriod } from "@cinch-labs/shared-util";
dayjs.extend(utc);
export const checkCinchCareEligibility = (vehicleOrder, allCinchCareOrders)=>{
    var ref;
    const deliverySlotDate = (ref = vehicleOrder.deliverySlot) === null || ref === void 0 ? void 0 : ref.date;
    const deliveryDate = deliverySlotDate ? dayjs.utc(deliverySlotDate) : null;
    if (!orderIsCompleted(vehicleOrder.orderStatus) || !vehicleOrder.completedAt) {
        return false;
    }
    const postDeliveryEligibleWarrantyPeriodDays = getCinchCareEligibilityPeriod(vehicleOrder.completedAt);
    const isWithinEligiblePostDeliveryPeriod = dayjs.utc().subtract(postDeliveryEligibleWarrantyPeriodDays, "days").subtract(1, "days");
    const alreadyHasCinchCare = allCinchCareOrders === null || allCinchCareOrders === void 0 ? void 0 : allCinchCareOrders.map((plan)=>plan.vehicleVrm).includes(vehicleOrder.vehicle.vrm);
    if (!deliveryDate || alreadyHasCinchCare) {
        return false;
    }
    return deliveryDate.isAfter(isWithinEligiblePostDeliveryPeriod, "seconds");
};
const orderIsCompleted = (orderStatus)=>orderStatus === "OrderCompleted" || orderStatus === "Fulfilled";
