export function checkOrderHasExpired(activeOrder, callback) {
    if (!activeOrder) {
        return;
    }
    const activeOrderExpiresCancelsAt = activeOrder.expiresAt ?? activeOrder.cancelledAt;
    if (!activeOrderExpiresCancelsAt) {
        return;
    }
    const timeDifference = activeOrderExpiresCancelsAt - Date.now();
    if (timeDifference <= 0) {
        callback();
        return;
    }
    setTimeout(callback, timeDifference);
}
