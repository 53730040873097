export const mockSearchResults = {
    pageNumber: 1,
    pageSize: 32,
    searchResultsCount: 385,
    vehicleListings: [
        {
            updatedAt: "2023-07-24T15:34:31.000Z",
            updatedBy: "Product.VehicleImageDataUpdated",
            firstPublishedDate: "2021-04-23T11:18:18.699Z",
            vehicleId: "8768bea7-a59a-4363-a44b-db6946f17075",
            modelYear: 2014,
            vehicleYear: 2015,
            bodyType: "HATCHBACK",
            colour: "GREY",
            doors: 5,
            engineCapacityCc: 999,
            fuelType: "Petrol",
            vrm: "LT15BUH",
            published: true,
            isReserved: false,
            make: "Ford",
            mileage: 50043,
            price: 12000,
            priceIncludingAdminFee: 12099,
            seats: 5,
            model: "Focus",
            stockType: "cinch",
            trim: null,
            variant: "1.0 EcoBoost 125 Zetec 5dr",
            thumbnailUrl: "",
            transmissionType: "MANUAL",
            milesPerGallon: 60,
            driveType: null,
            tags: [],
            discountInPence: 0,
            depositContributionInPence: 0,
            promotionId: null,
            engineSize: 999,
            fullRegistration: "LT15BUH",
            isAvailable: true,
            selectedModel: "Focus",
            quoteType: "hp",
            quoteBalanceInPence: 1000000,
            quoteApr: 11.41,
            quoteRegularPaymentInPence: 25758,
            quoteTermMonths: 48,
            quoteDepositInPence: 200000,
            quoteChargesInPence: 237384
        }
    ],
    facets: {
        makes: [
            {
                name: "Volvo",
                doc_count: 332,
                models: [
                    {
                        name: "S60",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "S90",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "V40",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "V40 Cross Country",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "V60",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "V90",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "XC40",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "XC60",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "XC70",
                        doc_count: 0,
                        variants: []
                    },
                    {
                        name: "XC90",
                        doc_count: 0,
                        variants: []
                    }
                ]
            }
        ],
        transmissionType: {
            manual: 353,
            automatic: 32
        },
        fuelType: {
            diesel: 203,
            petrol: 182,
            "petrol hybrid": 0,
            "petrol plug-in hybrid": 0,
            electric: 0,
            "diesel plug-in hybrid": 0,
            "electric range extender": 0,
            "diesel hybrid": 0,
            "diesel/electric": 0,
            "petrol/electric": 0,
            "plug-in hybrid": 0
        },
        doors: {
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 385
        },
        colour: {
            grey: 81,
            black: 99,
            white: 32,
            blue: 84,
            silver: 78
        },
        bodyType: {
            hatchback: 359,
            saloon: 0,
            suv: 0,
            estate: 0,
            coupe: 0,
            mpv: 0,
            convertible: 0
        },
        seats: {
            "4": 0,
            "5": 385
        },
        engineCapacityCc: {
            "0": 0,
            "900": 0,
            "1000": 168,
            "1500": 212
        },
        priceBucket: {
            "11000-12000": 179,
            "15000-20000": 12,
            "10000-11000": 20
        },
        monthlyPriceBucket: {
            "25000-30000": 120,
            "15000-20000": 113
        },
        vehicleYear: {
            "2015": 2,
            "2016": 45,
            "2017": 65,
            "2018": 206,
            "2019": 66
        },
        mileage: {
            "0": 1,
            "5000": 2,
            "10000": 18,
            "15000": 44
        },
        driveType: {
            "four-wheel drive": 0,
            "front-wheel drive": 1,
            "rear-wheel drive": 0
        },
        features: {
            navigation: 201,
            "heated seats": 80,
            "parking sensors": 13,
            sunroof: 0,
            bluetooth: 6,
            "apple carplay": 2,
            "android auto": 2
        },
        store: {
            Bristol: 5
        },
        tags: {
            "cars-on-offer": 0,
            "nearly-new": 0
        }
    }
};
