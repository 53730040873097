/**
 * Currently, the front-end matches cinchCare orders back to the vehicle details using the VRM
 * So it is necessary for this VRM to match the stubs in __stubs__/order-states.ts
 * otherwise tests will break 😬
 */ export const stubCompletedCinchCareOrder = {
    annualMileage: 12000,
    vehicleVrm: "YY66SYA",
    planNumber: "policy-id",
    price: 3499,
    startDate: "2021-01-01",
    coverLevel: "",
    modified: ""
};
