export const mileageData = [
    {
        label: "Up to 5,000 miles",
        value: 5000
    },
    {
        label: "Up to 10,000 miles",
        value: 10000
    },
    {
        label: "Up to 15,000 miles",
        value: 15000
    },
    {
        label: "Up to 20,000 miles",
        value: 20000
    },
    {
        label: "Up to 25,000 miles",
        value: 25000
    },
    {
        label: "Up to 30,000 miles",
        value: 30000
    },
    {
        label: "Up to 35,000 miles",
        value: 35000
    },
    {
        label: "Up to 40,000 miles",
        value: 40000
    },
    {
        label: "Up to 45,000 miles",
        value: 45000
    },
    {
        label: "Up to 50,000 miles",
        value: 50000
    },
    {
        label: "Up to 55,000 miles",
        value: 55000
    },
    {
        label: "Up to 60,000 miles",
        value: 60000
    },
    {
        label: "Up to 65,000 miles",
        value: 65000
    },
    {
        label: "Up to 70,000 miles",
        value: 70000
    }
];
