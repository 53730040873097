export var EventActions;
(function(EventActions) {
    EventActions["button"] = "button";
    EventActions["click"] = "click";
    EventActions["cinchcareAddedToOrder"] = "cinchCare added to order";
    EventActions["modalOpened"] = "modal-opened";
    EventActions["modalClosed"] = "modal-closed";
    EventActions["returnToCheckout"] = "return-to-checkout";
    EventActions["orderCompleteClick"] = "order complete click";
    EventActions["orderDeclinedClick"] = "order declined click";
    EventActions["orderTrackingViewed"] = "order tracking viewed";
    EventActions["filter"] = "filter";
    EventActions["navigate"] = "navigate";
    EventActions["noReviewsFound"] = "no reviews found";
    EventActions["saveQuote"] = "save-quote";
    EventActions["personalDetails"] = "personal details";
    EventActions["savePersonalDetails"] = "save personal details";
    EventActions["cancelPersonalDetails"] = "cancel personal details";
    EventActions["firstNameDetails"] = "first name details";
    EventActions["lastNameDetails"] = "last name details";
    EventActions["mobileNumberDetails"] = "mobile number details";
    EventActions["changePassword"] = "change password";
    EventActions["marketingPreferences"] = "marketing preferences";
    EventActions["logOut"] = "log out";
    EventActions["view"] = "view";
})(EventActions || (EventActions = {}));
export var EventCategories;
(function(EventCategories) {
    EventCategories["button"] = "button";
    EventCategories["cinchcare"] = "cinchcare";
    EventCategories["favourites"] = "Favourites";
    EventCategories["orders"] = "Orders";
    EventCategories["orderTracking"] = "Order Tracking";
    EventCategories["profileDetails"] = "Profile Details";
    EventCategories["myProfile"] = "My profile";
    EventCategories["reviews"] = "reviews";
    EventCategories["personalDetails"] = "personal details";
    EventCategories["adminFee"] = "admin fees";
    EventCategories["financeChangeCar"] = "finance change car";
})(EventCategories || (EventCategories = {}));
export var EventNames;
(function(EventNames) {
    EventNames["click"] = "click";
    EventNames["error"] = "error";
    EventNames["identify"] = "identify";
    EventNames["load"] = "load";
    EventNames["impression"] = "impression";
    EventNames["scAdd"] = "scAdd";
})(EventNames || (EventNames = {}));
export var TrackingEventTypes;
(function(TrackingEventTypes) {
    TrackingEventTypes["TRACK"] = "trackQueue";
    TrackingEventTypes["IDENTIFY"] = "identifyQueue";
    TrackingEventTypes["PAGE"] = "pageQueue";
    TrackingEventTypes["ADOBE"] = "adobeQueue";
    TrackingEventTypes["HOTJAR_EVENT"] = "hotJarEventQueue";
    TrackingEventTypes["HOTJAR_TAG"] = "hotJarTagQueue";
    TrackingEventTypes["HOTJAR_TRIGGER"] = "hotJarTriggerQueue";
    TrackingEventTypes["PAGE_NO_ADOBE"] = "pageQueueNoAdobe";
})(TrackingEventTypes || (TrackingEventTypes = {}));
export var HotJarTriggers;
(function(HotJarTriggers) {
    HotJarTriggers["MYPROFILE_WITH_ORDERS"] = "myprofile_with_orders";
})(HotJarTriggers || (HotJarTriggers = {}));
