import { Env, readFromEnv } from "@cinch-nx/environments";
import axios from "axios";
import useSWR from "swr";
export const VEHICLE_SEARCH_URL = `${readFromEnv(Env.SearchServiceUrl)}/vehicles`;
const fetcher = (url)=>axios.get(url).then((res)=>res.data);
export const useLatestVehicles = ({ make , model , caller , tags , fuelType , bodyType , seats , colour , numberOfVehicles , count =!numberOfVehicles ? 3 : numberOfVehicles  })=>{
    const queryObject = {
        sortingCriteria: "13"
    };
    let browseButtonUrl = "/used-cars";
    if (caller === "seo-pages") {
        const searchFilter = {
            tags,
            fuelType,
            bodyType,
            seats,
            colour
        };
        for(const key in searchFilter){
            if (Object.prototype.hasOwnProperty.call(searchFilter, key) && searchFilter[key] && searchFilter[key] !== "") {
                const filter = key;
                const filterValue = searchFilter[key];
                queryObject[filter] = filterValue;
                browseButtonUrl = `/used-cars?${filter}=${filterValue}`;
                break;
            }
        }
    } else {
        queryObject["make"] = make ?? "";
        if (model) {
            queryObject["selectedModel"] = model;
        }
        if (fuelType) {
            queryObject["fuelType"] = fuelType;
        }
    }
    const qs = new URLSearchParams(queryObject);
    const url = `${VEHICLE_SEARCH_URL}?${qs.toString()}`;
    const { data , error  } = useSWR(url, fetcher);
    const countOfData = data === null || data === void 0 ? void 0 : data.searchResultsCount;
    data === null || data === void 0 ? void 0 : data.vehicleListings.splice(count);
    return {
        data,
        countOfData,
        browseButtonUrl,
        error
    };
};
