import { UserPreference } from "../lib/data-user";
export const stubAuthenticatedUserState = {
    status: "valid",
    token: {
        access_token: "access_token",
        expires_at: Date.now() / 1000 + 60 * 60,
        profile: {
            sub: "user-id",
            "https://cinch.co.uk/user_id": "123567"
        }
    },
    profile: {
        firstName: "tester",
        lastName: "rantzen",
        email: "tester.rantzen@email.com",
        phoneNumber: "01234567910",
        preferenceDigital: false,
        preferenceEmail: false,
        preferenceSms: false,
        preferenceCall: UserPreference.NO
    }
};
