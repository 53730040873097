import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
export const sendCheckoutError = (description, error, orderId)=>{
    const errorPayload = axios.isAxiosError(error) ? {
        message: error.message,
        response: error.response
    } : error;
    console.log({
        errorPayload
    });
    datadogRum.addError(description, {
        location: "checkout-service-nx",
        orderId,
        error: errorPayload
    });
};
