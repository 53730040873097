import { TrackingEventTypes } from "@cinch-nx/shared-analytics";
export const createSocialMediaLinkTrackingEvent = (linkName)=>({
        eventName: `request-callback-link-click-event`,
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: "click",
                category: "footer",
                action: linkName,
                label: "leads"
            }
        }
    });
