export const toUrlSearchParams = (params)=>{
    const urlParams = new URLSearchParams();
    Object.entries(params ?? {}).forEach(([key, value])=>{
        if (typeof value === "string") {
            urlParams.append(key, value);
            return;
        }
        if (Array.isArray(value)) {
            value.forEach((nestedValue)=>{
                if (typeof nestedValue === "string") {
                    urlParams.append(key, nestedValue);
                } else {
                    urlParams.append(key, JSON.stringify(nestedValue));
                }
            });
            return;
        }
        urlParams.append(key, JSON.stringify(value));
    });
    return urlParams;
};
