const ADMIN_FEE = 99;
export const sentenceCase = (input)=>input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
export const formatEngineSizeInLitres = (engineCapacityInCc)=>engineCapacityInCc ? `${(engineCapacityInCc / 1000).toFixed(1)}L` : "";
export const formatFuelType = (input)=>{
    if (!input) {
        return "-";
    }
    return input.toUpperCase() === "LPG" ? input.toUpperCase() : sentenceCase(input);
};
export const formatMileage = (mileage)=>Intl.NumberFormat().format(mileage);
export const calculateVehiclePriceWithAdminFee = (price)=>price + ADMIN_FEE;
