import { EventActions, EventCategories, EventNames, TrackingEventTypes, useAnalytics } from "@cinch-nx/shared-analytics";
export const useTrackOrderTracking = ()=>{
    const trackEvent = useAnalytics((state)=>state.trackEvent);
    const onTrackOrderClick = (orderRef, deliveryDate)=>{
        const evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.orderTracking,
                action: EventActions.click,
                value: orderRef,
                label: deliveryDate
            }
        };
        trackEvent({
            type: TrackingEventTypes.ADOBE,
            eventName: "Profile - View order tracking clicked",
            data: evt
        });
    };
    const orderStatusesOnPageLoad = (ordersState)=>{
        const evt = {
            event: {
                name: EventNames.load,
                category: EventCategories.myProfile,
                value: ordersState,
                label: "myProfileOrderStatus"
            }
        };
        trackEvent({
            type: TrackingEventTypes.ADOBE,
            eventName: "Profile - Orders state on page load",
            data: evt
        });
    };
    const orderAwaitingFinanceDepositOnPageLoad = ()=>{
        const evt = {
            event: {
                name: EventNames.load,
                category: EventCategories.myProfile,
                value: "awaitingFinanceDeposit",
                label: "myProfileOrderStatus"
            }
        };
        trackEvent({
            type: TrackingEventTypes.ADOBE,
            eventName: "Profile - Orders that are Awaiting Finance Deposit on page load",
            data: evt
        });
    };
    return {
        onTrackOrderClick,
        orderStatusesOnPageLoad,
        orderAwaitingFinanceDepositOnPageLoad
    };
};
