import { groupDestinations } from "../utils/group-destinations";
export class SegmentPreferences {
    constructor(destinations, setPreferences){
        this.destinations = destinations;
        this.setPreferences = setPreferences;
    }
    setCategoryPreference = (selectedCategory, isSelected)=>{
        const destination = this.destinations.find((x)=>x.category === selectedCategory);
        if (destination) {
            this.setPreferences({
                [destination.id]: isSelected
            });
        }
    };
    getSelectedDestinations = (preferences)=>this.destinations.filter((dest)=>preferences[dest.id] === true);
    getDestinationsGroupedByCategory = ()=>groupDestinations(this.destinations, "category");
}
