import { useCallback, useEffect, useState } from "react";
const defaultOptions = {
    updateOnResize: true,
    updateOnOrientationChange: true
};
export const useViewport = (options = defaultOptions)=>{
    const [vw, setVW] = useState(0);
    const [vh, setVH] = useState(0);
    const { updateOnResize , updateOnOrientationChange  } = options;
    const setSizes = useCallback(()=>{
        if (window.innerWidth !== vw) {
            setVW(window.innerWidth);
        }
        if (window.innerHeight !== vh) {
            setVH(window.innerHeight);
        }
    }, [
        vw,
        vh
    ]);
    useEffect(()=>{
        setSizes();
        if (updateOnResize) {
            window.addEventListener("resize", setSizes);
            return ()=>window.removeEventListener("resize", setSizes);
        }
        if (updateOnOrientationChange) {
            window.addEventListener("orientationchange", setSizes);
            return ()=>window.removeEventListener("orientationchange", setSizes);
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return ()=>{};
    }, [
        updateOnResize,
        updateOnOrientationChange,
        vw,
        vh
    ]);
    return {
        vw,
        vh
    };
};
export default useViewport;
