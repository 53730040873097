import { traceFunction, tagError, setTag } from "@core/datadog-server";
import { STORYBLOK_BFF_URL } from "../../util/api";
import { toUrlSearchParams } from "../../util/to-url-search-params";
import { getDatasourceEntriesParams, getDatasourceEntriesResponse } from "./schema";
export async function getDatasourceEntriesfn(params, { storyblokUrl =STORYBLOK_BFF_URL  } = {}) {
    const urlParams = getDatasourceEntriesParams.transform(toUrlSearchParams).parse(params);
    const url = `${storyblokUrl}/get-datasource-entries?${urlParams.toString()}`;
    try {
        const request = await fetch(url);
        setTag("request.url", url);
        setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
        setTag("request.method", "GET");
        setTag("request.status", request.status);
        if (request.status !== 200) {
            throw new Error(`'get-datasource-entries' responded with non-OK response status ${request.status}`);
        }
        return getDatasourceEntriesResponse.parse(await request.json());
    } catch (error) {
        if (typeof error === "string" || error instanceof Error) {
            tagError(error);
        }
        if (error instanceof Error) {
            // eslint-disable-next-line unicorn/prefer-type-error
            throw new Error(`'get-datasource-entries' endpoint threw an error: ${error.message}`);
        }
        throw new Error(`'get-datasource-entries' endpoint threw an unknown error: ${error}`);
    }
}
export const getDatasourceEntries = traceFunction("storyblok.getDatasourceEntries", getDatasourceEntriesfn);
