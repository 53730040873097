import { track } from "@cinch-labs/customer-data-segment";
export const cookieSettings = (settings)=>{
    track("Cookie Settings", settings);
};
export const cookieBannerMetrics = (settings)=>{
    track("Cookie Banner metrics", settings);
};
export const cookieEditorShown = (timeStamp)=>{
    track("Cookie Editor Shown", {
        event_timing: timeStamp
    });
};
