import { ZendeskMessengerKey } from "./zendesk-messenger.types";
export const getZendeskScriptUrl = (env)=>{
    const key = env === "production" ? ZendeskMessengerKey.production : ZendeskMessengerKey.development;
    return `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
};
export const zendeskMessengerScriptProps = ({ id ="ze-snippet" , env ="localhost"  })=>({
        id,
        src: getZendeskScriptUrl(env),
        "data-testid": id
    });
