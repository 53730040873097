import '@cinch-labs/design-system/dist/index.css'
import '@cinch-labs/cds-tokens/dist/css/cds-tokens.css'
import Head from 'next/head'
import { registerRumContext } from '@cinch-labs/shared-util'
import { useUserStore } from '@cinch-nx/data-user'
import { ErrorBoundaryWrapper } from '@cinch-nx/landing-feature-error-page'
import {
  AnalyticsManager,
  preserveReferrerAndCID,
} from '@cinch-nx/shared-analytics'
import { CookieConsentWrapper } from '@cinch-nx/shared-ui'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'react-slidedown/lib/slidedown.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { cookieSettings, getCookieSettings } from '@cinch-nx/cookie-consent'

import '../stores'
import './_app.css'
import {
  Layout,
  AppPropsWithLayoutPropsWithError,
} from '@cinch-nx/landing-ui-shared'

const ClientSideHead = dynamic(() => import('../components/client-side-head'), {
  ssr: false,
})
export type DatadogMeta = {
  traceId: string | undefined
  traceTime: number
}

function CustomApp({
  Component,
  pageProps,
  err,
}: AppPropsWithLayoutPropsWithError) {
  const { layoutProps, ...componentProps } = pageProps
  const { ddMeta } = componentProps

  const [story, setChangedStory] = useState(pageProps.story)
  const { fetchUser } = useUserStore()

  const BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID
  registerRumContext(BUILD_ID)

  useEffect(() => {
    if (
      localStorage.getItem('updatedCookiePreferences') ||
      localStorage.getItem('cookieConsentPreferences')
    ) {
      const time = Number.parseInt(
        localStorage.getItem('lastExternalReferrerTime'),
        10,
      )
      let preferences = {
        functional: true,
        advertising: true,
        analytics: true,
      }
      if (!localStorage.getItem('cookieConsentPreferences')) {
        preferences = JSON.parse(
          localStorage.getItem('updatedCookiePreferences'),
        )
      }

      const cookieSetting = getCookieSettings(
        time,
        preferences,
        localStorage.getItem('cookieConsentPreferences') ? 'initial' : 'edited',
      )
      cookieSettings(cookieSetting)
      localStorage.removeItem('updatedCookiePrefs')
      localStorage.removeItem('cookieConsentPreferences')
    }
    preserveReferrerAndCID()
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnload = () => {
    const { StoryblokBridge, location } = window
    const storyblokInstance = new StoryblokBridge()
    storyblokInstance.on(['published', 'change'], () => {
      // reload page if save or publish is clicked
      location.reload()
    })

    storyblokInstance.on('input', (payload) => {
      setChangedStory(payload.story)
    })
  }
  if (componentProps.story && story) {
    componentProps.story = story
  }

  return (
    <ErrorBoundaryWrapper>
      <ClientSideHead />
      <AnalyticsManager />

      {pageProps.preview && (
        // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
        <Script
          src="https://app.storyblok.com/f/storyblok-v2-latest.js"
          type="text/javascript"
          onLoad={handleOnload}
        />
      )}
      {ddMeta ? (
        <Head>
          <meta name="dd-trace-id" key="dd-trace-id" content={ddMeta.traceId} />
          <meta
            name="dd-trace-time"
            key="dd-trace-time"
            content={`${ddMeta.traceTime}`}
          />
        </Head>
      ) : null}

      <Layout {...layoutProps} useVerticalGradient showBorderRadius>
        <>
          <Component {...componentProps} err={err} />
          <CookieConsentWrapper />
        </>
      </Layout>
    </ErrorBoundaryWrapper>
  )
}

export default CustomApp
