import { getStories } from "../get-stories/get-stories";
import { traceFunction, setTag, tagError } from "@core/datadog-server";
const MAX_PER_PAGE = 100;
/**
 * Gets all of the stories based on the `starts_with` slug.
 * @param params
 * @returns {Promise<GetStoriesResponse<Content>[]>}
 */ async function getAllStoriesFn(params) {
    const requestParams = {
        ...params,
        page: 1,
        per_page: MAX_PER_PAGE
    };
    setTag("getAllStories.request[0].params", requestParams);
    const initialRequest = await getStories(requestParams);
    const total = (initialRequest === null || initialRequest === void 0 ? void 0 : initialRequest.total) ?? 0;
    let stories = initialRequest.stories ?? [];
    const numberOfExtraRequests = Math.ceil(total / MAX_PER_PAGE) - 1;
    setTag("getAllStories.requests.count", numberOfExtraRequests.toString());
    if (numberOfExtraRequests === 0) {
        return {
            total,
            stories
        };
    }
    const otherStories = await Promise.all(Array.from({
        length: numberOfExtraRequests
    }, (_val, idx)=>{
        const requestParams = {
            ...params,
            page: idx + 2,
            per_page: MAX_PER_PAGE
        };
        setTag(`getAllStories.request[${idx + 1}].params`, requestParams);
        return getStories(requestParams);
    })).then((responses)=>{
        return responses.filter(Boolean).flatMap((res)=>{
            return res === null || res === void 0 ? void 0 : res.stories;
        });
    }).catch((error)=>{
        if (typeof error === "string" || error instanceof Error) {
            tagError(error);
        }
        // rethrow the error so that the caller can handle it
        throw error;
    });
    stories = [
        ...new Set([
            ...stories,
            ...otherStories
        ])
    ];
    return {
        total,
        stories
    };
}
export const getAllStories = traceFunction("storyblok.getAllStories", getAllStoriesFn);
