import { sendDigitalDataEvent } from "../datalayer";
export const loggedInFavouriteEvent = (vehicle, action)=>{
    if (vehicle) {
        const payload = {
            event: {
                name: `logged-in-${action}`,
                category: "button",
                action,
                label: "logged in"
            },
            product: [
                {
                    vehicle: vehicle[0].vehicle
                }
            ]
        };
        sendDigitalDataEvent(payload);
    }
};
