export var TrackingEventTypes;
(function(TrackingEventTypes) {
    TrackingEventTypes["TRACK"] = "trackQueue";
    TrackingEventTypes["IDENTIFY"] = "identifyQueue";
    TrackingEventTypes["PAGE"] = "pageQueue";
    TrackingEventTypes["ADOBE"] = "adobeQueue";
    TrackingEventTypes["HOTJAR_EVENT"] = "hotJarEventQueue";
    TrackingEventTypes["HOTJAR_TAG"] = "hotJarTagQueue";
    TrackingEventTypes["HOTJAR_TRIGGER"] = "hotJarTriggerQueue";
})(TrackingEventTypes || (TrackingEventTypes = {}));
