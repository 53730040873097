const getCookie = ({ name  })=>{
    var ref;
    return ((ref = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)) === null || ref === void 0 ? void 0 : ref.pop()) ?? undefined;
};
const cookiePreferencesCookieParser = (cookieContents)=>cookieContents ? JSON.parse(cookieContents) : undefined;
const cookiePreferences = ()=>cookiePreferencesCookieParser(getCookie({
        name: "cp"
    }));
export const hasConsentedToCookies = (type)=>{
    var ref;
    return ((ref = cookiePreferences()) === null || ref === void 0 ? void 0 : ref[type]) ?? false;
};
