export const mockStory = {
    alternates: [],
    content: {
        _uid: "6922e73c-ecd4-402c-828f-cc7ea1d99b79",
        component: "SearchPage",
        FAQ: [
            {
                _uid: "6d5d233d-b76e-49a2-925d-56a7f4e031a1",
                faqs: [
                    {
                        _uid: "b768c1d6-8e84-4048-a15f-163a33a91bb9",
                        body: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "The cost of a used Honda on ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "finance",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/car-finance",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " will depend on the model you choose and the ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "type of finance",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/guides/ask-the-experts/buying-a-car-best-financing-option",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " you go for, as these factors will impact your monthly repayments. You can get a quote using our online finance calculator, or take a look at our ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "finance guides",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/guides/car-finance",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " to learn more. \xa0",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        title: "How much is a used Honda on finance?\xa0",
                        component: "SearchAccordionItem"
                    },
                    {
                        _uid: "f2f78cb6-cb80-49f2-8ea9-aa1e0e3657a6",
                        body: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "All Hondas are known for superb ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "reliability",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/guides/choosing-a-car/choosing-a-reliable-car",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: ", and this is thanks to their durable engine options, solid build quality and low running costs.\xa0",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        title: "Are Hondas reliable? ",
                        component: "SearchAccordionItem"
                    },
                    {
                        _uid: "bb69b384-ef5a-459f-9ae3-7e9f5d970426",
                        body: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "If you want a city car then we’d suggest looking at the all-electric Honda e or ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "Jazz",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/used-cars/honda/jazz/review",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: ". However, if you want something a little bigger then the ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "Civic",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/used-cars/honda/civic/review",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " would be best suited to you. ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "If you want a large car that's more than happy on longer journeys, then the ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "HR-V",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/used-cars/honda/hr-v/review",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " or ",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: "CR-V",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "link",
                                                    attrs: {
                                                        href: "https://www.cinch.co.uk/used-cars/honda/cr-v/review",
                                                        uuid: null,
                                                        anchor: null,
                                                        target: null,
                                                        linktype: "url"
                                                    }
                                                },
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            text: " is the one for you.\xa0",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        title: "Which model of Honda is best? ",
                        component: "SearchAccordionItem"
                    },
                    {
                        _uid: "312ad6ee-f6a2-4d2e-bd28-1e407a30c4dd",
                        body: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "Honda is from Japan and was founded by Soichiro Honda in 1948.\xa0",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        title: "Where is Honda from? ",
                        component: "SearchAccordionItem"
                    },
                    {
                        _uid: "c1d138e0-7c49-4918-9ce2-4aec64f93e70",
                        body: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            text: "Honda has been making family vehicles for decades now and we see no reason why any of its models wouldn’t make a great family car.\xa0\xa0",
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "styled",
                                                    attrs: {
                                                        class: ""
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        title: "Are Hondas good family vehicles? ",
                        component: "SearchAccordionItem"
                    }
                ],
                title: "Should I buy a used Honda?",
                component: "SearchFaqSection"
            }
        ],
        SEO: [
            {
                _uid: "3b1d1824-f016-43ef-b477-c4516234a102",
                HowTo: [],
                title: "Used Honda cars for sale or on finance  ",
                robots: [],
                component: "SEO",
                openGraph: [],
                description: "Used Honda cars for sale or on finance from cinch.co.uk. Get home delivery and a 90-day warranty. Part exchange available",
                twitterCard: [],
                canonicalUrl: ""
            }
        ],
        Intro: [
            {
                Body: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "A used Honda is a top choice for a reliable car that’s well built, high-quality, and very easy on the eye. \xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Honda’s most popular models in the UK include the\xa0Jazz,\xa0Civic\xa0and\xa0CR-V, all of which offer different things and with their own strengths.\xa0\xa0\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "New versions of Honda’s model lineup come as either hybrid or fully electric cars, showing off just how far it's come on in the fight for an all-electric future.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Choose a used Honda on finance or to purchase, to experience these popular cars at a great price. \xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                _uid: "f31ac952-00a6-44a0-9bfd-dfcd11f999c9",
                Heading: "Used Honda cars for sale",
                component: "SearchPageCopy"
            }
        ],
        Outro: [
            {
                Body: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "The ",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: "Ford",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://www.cinch.co.uk/used-cars/ford",
                                                uuid: null,
                                                anchor: null,
                                                target: "_self",
                                                linktype: "url"
                                            }
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: " Focus is a brilliant example of a car that’s perfect for everyday use but that doesn’t feel overly sensible. It’s got all the next-gen tech a driver could want – and you can even spice it up a bit with a powerful Focus ST-Line edition – but it’s still functional enough for the daily commute.\xa0\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "You can choose either a manual or automatic gearbox on a five-door, five-seater hatchback that packs around 300 litres in the boot, depending on the model.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "The choice of power will spoil you as well, as this ",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: "family hatchback",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://www.cinch.co.uk/guides/choosing-a-car/best-hatchbacks-for-around-15000",
                                                uuid: null,
                                                anchor: null,
                                                target: "_self",
                                                linktype: "url"
                                            }
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: " can hide a mighty engine that will have you grinning out on the roads. Plus, wouldn’t it just look gorgeous sat on your driveway?\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "bullet_list",
                            content: [
                                {
                                    type: "list_item",
                                    content: [
                                        {
                                            type: "paragraph",
                                            content: [
                                                {
                                                    text: "Ford Focus vs. Ford Fiesta comparison review",
                                                    type: "text",
                                                    marks: [
                                                        {
                                                            type: "link",
                                                            attrs: {
                                                                href: "https://www.cinch.co.uk/guides/choosing-a-car/ford-focus-vs-ford-fiesta-which-is-better",
                                                                uuid: null,
                                                                anchor: null,
                                                                target: "_self",
                                                                linktype: "url"
                                                            }
                                                        },
                                                        {
                                                            type: "bold"
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                _uid: "6d0f7b81-5a90-4aa0-bc20-d7eb45049dca",
                Heading: "About the Ford Focus",
                component: "SearchPageCopy"
            },
            {
                Body: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "There's a reason why the Ford Focus has been on UK roads for so long – it’s got everything you need.\xa0\xa0\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Our newest Focus models are kitted out with ambient lighting set-ups, headlight washers, sat-nav systems, and those much-appreciated parking aids such as front and rear parking sensors and a comprehensive parking pilot in some models.\xa0\xa0\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "There’s also a good-sized boot that’ll serve you well through everyday life, and the interior is spacious and comfortable enough to fit a family without seeming cramped.\xa0\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Depending on the model you pick, you’ll get a few unique-to-that-car features. One of our favourite options is the Ford Focus Active line, which offers a traditional hatchback with a touch of SUV styling, and has extra features like handy roof rails.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Visit our ",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "italic"
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: "Ford Focus listings",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://www.cinch.co.uk/used-cars?bodyType=&colour=&doors=&driveType=&features=&fromEngineSize=-1&fromPrice=-1&fromYear=-1&fuelType=&make=Ford&mileage=-1&pageNumber=1&pageSize=32&seats=&selectedModel=Focus&sortingCriteria=2&tags=&toEngineSize=-1&toPrice=-1&toYear=-1&transmissionType=&useMonthly=false&variant=",
                                                uuid: null,
                                                anchor: null,
                                                target: "_self",
                                                linktype: "url"
                                            }
                                        },
                                        {
                                            type: "italic"
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: " to see the full range of features on offer in each model.",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "italic"
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                _uid: "ca5fcbc1-7b21-4f7a-b519-7802436b79ca",
                Heading: "What features does the Ford Focus have?",
                component: "SearchPageCopy"
            },
            {
                Body: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "The Ford Focus’s range of engines and trim levels all have their area of expertise.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "There’s the Ford Focus ST-Line collection for anyone who wants a real boost of power in their vehicle. This car can come with a turbocharged engine setup that has been known to reach 0-60mph in about six seconds.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "Even when you’re not putting your foot down, the Focus feels sublime on the roads. There’s a confidence in the way it moves – steady on the floor and gripping at corners, feeling just as good at lower speeds and shorter journeys as it does on road trips.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "You could even opt for the Ford Focus EcoBoost offerings for some ",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: "mild-hybrid",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://www.cinch.co.uk/guides/ask-the-experts/living-with-a-mild-hybrid-car--is-going-mhev-worth",
                                                uuid: null,
                                                anchor: null,
                                                target: "_self",
                                                linktype: "url"
                                            }
                                        },
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: " tech and a reduced-size engine, bringing you a surprisingly quiet car that doesn’t feel like it’s lacking anything.\xa0",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "styled",
                                            attrs: {
                                                class: ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                _uid: "45edf0e5-ffd0-42ef-becb-40b923f2a0a9",
                Heading: "What is the\xa0Ford Focus\xa0like to drive?",
                component: "SearchPageCopy"
            }
        ],
        HeadingOverride: "This is a page heading"
    },
    created_at: "2023-09-07T10:22:48.157Z",
    full_slug: "search/honda/",
    group_id: "4b777207-eabd-491e-b4eb-872200859e3f",
    id: 367026535,
    is_startpage: true,
    meta_data: null,
    name: "honda",
    parent_id: 367026534,
    position: -200,
    published_at: "2023-09-07T10:22:48.259Z",
    first_published_at: "2023-09-07T10:22:48.259Z",
    slug: "honda",
    lang: "default",
    translated_slugs: undefined,
    default_full_slug: undefined,
    sort_by_date: null,
    tag_list: [],
    uuid: "7dcfb9a8-f6c9-4beb-931a-f9e89ba891ba",
    path: "used-cars/honda/",
    rels: []
};
/** This has a missing class element located in
 * body, content[0], content[0], marks[0], attrs
 */ export const mockFAQWithError = {
    _uid: "6d5d233d-b76e-49a2-925d-56a7f4e031a1",
    title: "Should I buy a used Honda?",
    component: "SearchFaqSection",
    faqs: [
        {
            _uid: "b768c1d6-8e84-4048-a15f-163a33a91bb9",
            body: {
                type: "doc",
                content: [
                    {
                        type: "paragraph",
                        content: [
                            {
                                text: "The cost of a used Honda on ",
                                type: "text",
                                marks: [
                                    {
                                        type: "styled"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            title: "How much is a used Honda on finance?\xa0",
            component: "SearchAccordionItem"
        }
    ]
};
