export var EventActions;
(function(EventActions) {
    EventActions["click"] = "click";
    EventActions["cinchcareAddedToOrder"] = "cinchCare added to order";
    EventActions["modalOpened"] = "modal-opened";
    EventActions["modalClosed"] = "modal-closed";
    EventActions["returnToCheckout"] = "return-to-checkout";
    EventActions["orderCompleteClick"] = "order complete click";
    EventActions["orderDeclinedClick"] = "order declined click";
    EventActions["orderTrackingViewed"] = "order tracking viewed";
    EventActions["deadClick"] = "dead click";
    EventActions["iDoNotWantThisCar"] = "i-do-not-want-this-car";
})(EventActions || (EventActions = {}));
export var EventCategories;
(function(EventCategories) {
    EventCategories["button"] = "button";
    EventCategories["cinchcare"] = "cinchcare";
    EventCategories["favourites"] = "Favourites";
    EventCategories["orders"] = "Orders";
    EventCategories["orderTracking"] = "Order Tracking";
    EventCategories["profileDetails"] = "Profile Details";
    EventCategories["myProfile"] = "My profile";
    EventCategories["image"] = "image";
})(EventCategories || (EventCategories = {}));
export var EventNames;
(function(EventNames) {
    EventNames["click"] = "click";
    EventNames["error"] = "error";
    EventNames["identify"] = "identify";
    EventNames["load"] = "load";
})(EventNames || (EventNames = {}));
export var ModalState;
(function(ModalState) {
    ModalState["active"] = "active";
    ModalState["expiringSoon"] = "expiring";
    ModalState["expiredStillAvailable"] = "expired-unreserved";
    ModalState["expiredUnavailable"] = "expired-reserved";
})(ModalState || (ModalState = {}));
