import TargetClient from "@adobe/target-nodejs-sdk";
import nookies from "nookies";
import { getActivityId, getActivityLocationId, timeoutPromise } from "../helpers";
import { targetClientOptions, CLIENT_TIMEOUT } from "./config";
import { TARGET_CLIENT_ERROR_MESSAGES as ERROR_MESSAGES } from "./errors";
let targetClient;
/**
 * Initialises the target client and awaits for this to become ready, then calls
 * the client to retrieve the specified target activity.
 *
 * @param ssrCtx The server-side context object from getServerSideProps
 * @param activityLocationId The activity location or mbox name
 * @param targetActivityConst The activity constant
 * @returns The target activity object or null
 */ export async function getAdobeTargetSSR(ssrCtx, activityLocationConst, targetActivityConst) {
    const cookies = nookies.get(ssrCtx);
    const checkCookiesAccepted = checkAnalyticCookieExists(cookies);
    if (!checkCookiesAccepted) {
        return null;
    }
    // Initialise the client
    targetClient = TargetClient.create({
        ...targetClientOptions,
        events: {
            artifactDownloadFailed: (event)=>handleArtifactDownloadFailure(event)
        }
    });
    try {
        var ref;
        // Wait for the client to complete it's initialisation before continuing
        await timeoutPromise(CLIENT_TIMEOUT, clientMonitor(), new Error(ERROR_MESSAGES.INIT_TIMEOUT));
        const isClientReady = (ref = targetClient.decisioningEngine) === null || ref === void 0 ? void 0 : ref.isReady();
        if (isClientReady) {
            const activityId = getActivityId(targetActivityConst);
            const activityLocationId = getActivityLocationId(activityLocationConst);
            const targetCookie = cookies[TargetClient.TargetCookieName] // 'mbox'
            ;
            const targetActivity = await fetchTargetActivity(activityLocationId, targetCookie, activityId, ssrCtx);
            return targetActivity;
        } else {
            throw new Error(ERROR_MESSAGES.TARGET_CLIENT_DECISIONING_ENGINE_NOT_READY);
        }
    } catch (error) {
        console.warn(error);
        return null;
    }
}
/**
 * Calls the initialised target client to retrieve the target activity object if it exists
 * @param activityLocationId The activity location ID
 * @param targetCookie The target cookie (string or undefined)
 * @param activityId The activity id from the environments file
 * @param ssrCtx The server-side context object from getServerSideProps
 * @returns The target activity object or null
 */ export async function fetchTargetActivity(activityLocationId, targetCookie, activityId, ssrCtx) {
    try {
        const request = {
            execute: {
                mboxes: [
                    {
                        address: {
                            url: `${ssrCtx.req.headers.host}${ssrCtx.req.url}`
                        },
                        name: activityLocationId
                    }
                ]
            }
        };
        const response = await targetClient.getOffers({
            request,
            targetCookie
        });
        if (!response) {
            throw new Error(ERROR_MESSAGES.FAILED_TO_FETCH_TARGET_ACTIVITIES);
        }
        const existingTargetCookie = targetCookie;
        /**
     * If the cookie doesn't exist, it means the user has accepted the cookie banner
     * and the page has reloaded. However, Adobe scripts haven't loaded by this point (as
     * we're still in the server and scripts run client), so we want to get the targetCookie
     * returned from the get attributes response and set it before the client loads.
     * */ if (!existingTargetCookie) {
            const newTargetCookie = response.targetCookie;
            nookies.set(ssrCtx, newTargetCookie.name, newTargetCookie.value, {
                maxAge: newTargetCookie.maxAge
            });
        }
        const targetActivity = getTargetActivity(Number(activityId), response.responseTokens);
        return targetActivity;
    } catch (error) {
        if (error instanceof Error) {
            throw new TypeError(error.message);
        }
        return null;
    }
}
/**
 * @param activityId The activity ID for the experiment
 * @param response OfferAttributesGetResponse
 * @returns The target activity object or appropriate errors if not found
 */ export function getTargetActivity(activityId, allTargetActivities) {
    if (!allTargetActivities) {
        throw new Error(ERROR_MESSAGES.NO_TARGET_ACTIVITIES_FOUND);
    }
    const targetActivity = allTargetActivities.find((activity)=>activity["activity.id"] === Number(activityId));
    if (!targetActivity) {
        throw new Error(ERROR_MESSAGES.TARGET_ACTIVITY_NOT_FOUND);
    }
    return targetActivity;
}
/**
 * Validates the correct cinch cookies (analytics) has been accepted
 * @param cookies
 * @returns boolean
 */ export function checkAnalyticCookieExists(cookies) {
    if (cookies["cp"]) {
        const parsedCinchCookies = JSON.parse(cookies["cp"]);
        if (parsedCinchCookies.analytics) return true;
    }
    return false;
}
/**
 * Monitors the target client initialisation. Will resolve the promise once the decisioning engine is ready.
 * @returns A promise that resolves when the property is available.
 */ function clientMonitor() {
    return new Promise((resolve)=>{
        const interval = setInterval(()=>{
            var ref;
            const result = targetClient;
            if ((ref = result.decisioningEngine) === null || ref === void 0 ? void 0 : ref.isReady()) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}
const handleArtifactDownloadFailure = (event)=>{
    throw new Error(`Artifact failed to download:\n${event}`);
};
